import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItemSliding,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  arrowBackCircle,
  arrowBackCircleOutline,
  ellipseOutline,
  link,
  mapOutline,
} from "ionicons/icons";
import _, { map } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Config from "../../config";
import { ICategory, ICategoryItem } from "../../Interfaces";
import MapBox, { updateMapBoxIndex } from "../MapBox";
import "./ListHeader.css";
import ListMap from "./ListMap";

interface iParams {
  list: ICategory;
}

export function updateMapIndex(item: any) {
  // console.log("header index", index);
  updateMapBoxIndex(item);
}

export default function ListHeader(params: iParams) {
  const state: any = useLocation().state;
  const [showModal, _showModal] = useState(false);
  let history = useHistory();

  useEffect(()=>{
    if(params.list && params.list.type && params.list.type == "map"){
      _showModal(true);
    }
  },[])

  return (
    <>
      <IonHeader style={{ backgroundColor: "transparent", marginBottom: -100 }}>
        <IonToolbar style={{ backgroundColor: "transparent" }}>
          <IonIcon
            onClick={() => {
              history.goBack();
            }}
            icon={arrowBackCircle}
            size="large"
          ></IonIcon>
          <IonIcon
            onClick={() => {
              history.goBack();
            }}
            style={{ color: "white", marginLeft: -32 }}
            icon={arrowBackCircleOutline}
            size="large"
          ></IonIcon>
        </IonToolbar>

        <ListMap
          center={{
            lat:
              params.list.center && params.list.center.lat
                ? params.list.center.lat
                : Config.center.lat,
            lon:
              params.list.center && params.list.center.lon
                ? params.list.center.lon
                : Config.center.lon,
          }}
          list={params.list}
          zoom={params.list.zoom ? params.list.zoom : Config.mapboxZoom}
          isTour={params.list.isTour}
          items={params.list.items}
          showModal={showModal}
          onDismiss={() => {
            _showModal(false);
          }}
        />
      </IonHeader>
      {params.list && params.list.image ? (
        <div
          className="ListHeader_container"
          style={{
            height: params.list.type == "tour" ? "50vh" : 170,
            backgroundImage: params.list.isTour
              ? "url()"
              : "url(" + params.list.image.url + ")",
          }}
        >
          {params.list.type == "tour" ? (
            <div>
              <MapBox
                height="50vh"
                list={params.list}
                onDismiss={() => {}}
                isTour={true}
                showClose={false}
                zoom={_.subtract(params.list.zoom, 1)}
              />
            </div>
          ) : null}
          {params.list.type != "tour" ? (
            <div className="ListHeader_text"> {params.list.name}</div>
          ) : null}
          {params.list.showMap == true && params.list.type != "tour" ? (
            <div
              style={{
                width: 80,
                height: 40,
                position: "absolute",
                bottom: 0,
                right: 0,
                paddingRight: 25,
                zIndex: 999,
                textAlign: "right",
              }}
              onClick={() => {
                _showModal(true);
              }}
            >
              <IonIcon
                icon={mapOutline}
                style={{ color: "white" }}
                size="large"
                slot="end"
              ></IonIcon>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
