import {
  IonList,
  IonContent,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonModal,
} from "@ionic/react";
import { list, search } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import Card2 from "../Card2";
import ListFooter from "./ListFooter";
import "./ListItems.css";
import * as _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import Card1 from "../Card1";
import Card3 from "../Card3";
import CachedImage from "../CachedImage";
import Db from "../../services/db";
import Card4 from "../Card4";
import Config from "../../config";
import ListMap from "./ListMap";
import MapBox from "../MapBox";

interface iParams {
  list: ICategory;
  refresh: (item: any) => void;
  onIndexChange: (item: any) => void;
}

export default function ListItems(params: iParams) {
  const [search, _search] = useState("");
  const [loading, _loading] = useState(false);

  const [showModal, _showModal] = useState(false);
  //const [currentScrollIndex, _currentScrollIndex] = useState(0);
  let history = useHistory();

  let currentScrollIndex = 0;

  function TourList() {
    let index = 0;

    let newList: any;

    newList = _.orderBy(
      _.uniq(params.list.items),
      ["order", "startDate", "name"],
      ["asc", "asc", "asc"]
    );

    return newList
      .filter((x: any) => {
        if (x.published_at != null) {
          return true;
        } else {
          return false;
        }
      })
      .filter((x: any) => {
        if (x.startDate) {
          let now = moment();
          let end = moment(x.endDate);
          if (end >= now) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .filter((x: any) => {
        if (
          !search ||
          search == "" ||
          x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
      .filter((item: any) => {
        return item.heroImage || item.image;
      })
      .map((item: any) => {
        index++;
        let action = {};
        if (item && item.actions) {
          action = item.actions[0];
        }

        let newSrc = item.heroImage.url;
        if (
          item.heroImage &&
          item.heroImage.formats &&
          item.heroImage.formats.thumbnail
        ) {
          newSrc = item.heroImage.formats.thumbnail.url;
        } else {
          newSrc = item.heroImage.url;
        }
        if (item.type == "categories") {
          return null;
        } else {
          return (
            <Card1
              width="220px"
              key={index}
              item={item}
              clicked={() => {
                history.push("/profile", {
                  item: item,
                  related: params.list.items,
                });
              }}
            />
          );
        }
      });
  }

  function ItemList() {
    let index = 0;

    let newList: any;

    if (params.list.isTour != true) {
      newList = _.orderBy(
        _.uniq(params.list.items),
        ["order", "startDate", "name"],
        ["asc", "asc", "asc"]
      );
    } else {
      newList = _.orderBy(_.uniq(params.list.items));
    }

    return newList
      .filter((x: any) => {
        if (x.published_at != null) {
          return true;
        } else {
          return false;
        }
      })
      .filter((x: any) => {
        if (x.startDate) {
          let now = moment();
          let end = moment(x.endDate);
          if (end >= now) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .filter((x: any) => {
        if (
          !search ||
          search == "" ||
          x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
      .filter((item: any) => {
        return item.heroImage || item.image;
      })
      .map((item: any) => {
        index++;
        let action = {};
        if (item && item.actions) {
          action = item.actions[0];
        }

        if (item.type == "categories") {
          return (
            <Card2
              key={index}
              name={item.name}
              image={item.image}
              resize={"cover"}
              action={null}
              startDate={item.startDate}
              endDate={item.endDate}
              shortDescription={item.description}
              clicked={() => {
                history.replace("/list/" + item.id, { item: item });
                _loading(true);
                params.refresh(item);
                setTimeout(() => {
                  _loading(false);
                }, 300);
              }}
            />
          );
        } else {
          return (
            <Card2
              key={index}
              name={item.name}
              image={item.heroImage}
              resize={item.HeroImageResize}
              startDate={item.startDate}
              endDate={item.endDate}
              action={action}
              shortDescription={item.shortDescription}
              clicked={() => {
                history.push("/profile", { item: item, related: newList });
              }}
            />
          );
        }
      });
  }

  return (
    <>
      {loading != true ? (
        <>
          {params.list.type !== "tour" ? (
            <IonContent
              className="List_content"
              style={{ backgroundColor: "#f7f7f7" }}
            >
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <ReactMarkdown>{params.list?.description}</ReactMarkdown>
              </div>

              <div className="ListItems_container">
                <IonList>
                  {params.list && params.list.items ? <ItemList /> : null}
                </IonList>
              </div>
            </IonContent>
          ) : (
            <IonContent className="List_content">
              {params.list && params.list.items ? (
                <>
                  <h2 style={{ margin: 20 }}>{params.list.name}</h2>
                  <IonButton
                    style={{ margin: 20 }}
                    shape="round"
                    expand="block"
                    color="secondary"
                    onClick={() => {
                      _showModal(true);
                    }}
                  >
                    Show Full Map
                  </IonButton>
                  <div
                    style={{
                      marginTop: 0,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    <ReactMarkdown>{params.list.description}</ReactMarkdown>
                  </div>
                  <div
                    style={{
                      marginTop: 30,
                      overflowX: "scroll",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      paddingLeft: 10,
                      paddingRight: 80,
                      marginRight: -30,
                      height: 215,
                    }}
                  >
                    <TourList />
                  </div>
                  <IonModal isOpen={showModal} cssClass="ListMap_container">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        height: "100vh",
                        width: "100vw",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "50vh",
                          left: "0",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Loading Map...
                      </div>
                      <MapBox
                        height="100vh"
                        list={params.list}
                        showClose={true}
                        onDismiss={() => {
                          _showModal(false);
                        }}
                        isTour={true}
                        zoom={params.list.zoom}
                      />
                    </div>
                  </IonModal>
                </>
              ) : null}
            </IonContent>
          )}
          {params.list.type !== "tour" ? (
            <ListFooter
              list={list}
              onSearch={(text) => {
                _search(text);
              }}
            />
          ) : null}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}
