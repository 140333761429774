import { useEffect, useState } from "react";
import { ICategoryItem, IImage } from "../Interfaces";
import "./Card2.css";
import moment from "moment";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Db from "../services/db";

interface ContainerProps {}

interface iParams {
  image: IImage;
  resize: string;
  startDate: string;
  endDate: string;
  shortDescription: string;
  name: string;
  action: any;
  clicked: () => void;
}

export default function Card2(params: iParams) {
  const [newSrc, _newSrc] = useState<any>();

  if (params.image && params.image.formats && params.image.formats.thumbnail) {
    Db.getSrc(params.image.formats.thumbnail.url).then((data: any) => {
      _newSrc(data);
    });
  } else {
    Db.getSrc(params.image.url).then((data: any) => {
      _newSrc(data);
    });
  }

  if (newSrc) {
    //console.log("Card2", params);

    return (
      <div className="Card2_card">
        {params && params.image ? (
          <IonGrid
            onClick={() => {
              params.clicked();
            }}
          >
            <IonRow key="r1">
              <IonCol
                key="r1"
                size="6"
                className="Card2_imageblock"
                style={{
                  backgroundImage: "url(" + newSrc + ") ",
                  backgroundSize: params.resize ? params.resize : "cover",
                }}
              >
                {params.action && params.action.completed == true ? (
                  <div className="Card2_success">
                    {params.action?.button_complete_text}
                  </div>
                ) : null}
              </IonCol>
              <IonCol size="6" key="r2">
                <div className="Card2_cardTitleBlockTitle">{params.name}</div>
                <div className="Card2_cardTitleBlockDates">
                  {params.startDate ? (
                    <>
                      {moment(params.startDate).format("M/D/YY")} -
                      {moment(params.endDate).format("M/D/YY")}
                    </>
                  ) : null}
                </div>
                <div className="Card2_cardTitleBlockDesc">
                  {params.shortDescription}
                  <br />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : null}
      </div>
    );
  } else {
    if (params.image) {
      return (
        <div className="Card2_card">
          <IonGrid>
            <IonRow key="r1">
              <IonCol key="r1" size="5" className="Card2_imageblock"></IonCol>
              <IonCol size="7" key="r2">
                <div className="Card2_cardTitleBlockTitle"></div>
                <div className="Card2_cardTitleBlockDates"></div>
                <div className="Card2_cardTitleBlockDesc">
                  <br />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      );
    } else {
      return null;
    }
  }
}
