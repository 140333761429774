import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonInput,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../components/BackButton";
import ImageModal from "../components/ImageModal";
import Card2 from "../components/Card2";
import Db from "../services/db";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Capacitor } from "@capacitor/core";
import "./VendorScan.css";
import _ from "lodash";
import { finished } from "stream";

function VendorScan() {
  const [vendor, _vendor] = useState<any>();
  const [result, _result] = useState<any>();
  const [scannedWallet, _scannedWallet] = useState<any>();
  const [scanning, _scanning] = useState(false);
  const [isMobile, _isMobile] = useState(false);
  const [showModal, _showModal] = useState(false);
  const [foundATip, _foundATip] = useState(false);
  const [finished, _finished] = useState(false);
  const [showFinish, _showFinish] = useState(false);

  const [showAlert, _showAlert] = useState(false);
  const [alertText, _alertText] = useState<any>();
  const [alertTitle, _alertTitle] = useState<any>();
  const [alertTimeout, _alertTimeout] = useState<any>();

  const [selectedTip, _selectedTip] = useState<any>();
  const [transactionSub, _transactionSub] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      _isMobile(true);
      didUserGrantPermission().then((data) => {
        startScan();
      });
      let localVendor: any = localStorage.getItem("vendor");
      _vendor(JSON.parse(localVendor));
    }
  }, []);

  async function didUserGrantPermission() {
    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });

    if (status.granted) {
      // user granted permission
      return true;
    }

    if (status.denied) {
      // user denied permission
      return false;
    }

    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = alert(
        "We need your permission to use your camera to be able to scan barcodes"
      );
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }

    // user did not grant the permission, so he must have declined the request
    return false;
  }

  async function startScan() {
    _scanning(true);
    BarcodeScanner.hideBackground(); // make background of WebView transparent

    const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
    _scanning(false);
    // if the result has content
    if (result.hasContent) {
      console.log(result.content); // log the raw scanned content
      _result(result.content);
      Db.Wallet(result.content).then((data) => {
        if (data && data[0] && data[0].id) {
          _scannedWallet(data[0]);
        } else {
          alert("Wallet not found");
        }
      });
    }
  }

  function ListActions() {
    if (
      scannedWallet &&
      scannedWallet.owner &&
      vendor.actions &&
      vendor.actions.actions
    ) {
      return vendor.actions.actions.map((action: any) => {
        return (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "small",
              paddingTop: 10,
            }}
          >
            {action.title}
            <IonButton
              onClick={() => {
                Db.VendorTakeAction(action.id, scannedWallet.owner).then(
                  (data) => {
                    if (data.success == true) {
                      _alertText(action.success_text);
                      _alertTitle("Success!!!");
                      setTimeout(() => {
                        _showAlert(true);
                      }, 100);
                      let timeout = setTimeout(() => {
                        _showAlert(false);
                        history.goBack();
                      }, 3000);
                      _alertTimeout(timeout);
                    } else {
                      if (data.status == "already taken") {
                        _alertText(
                          "This action has already been taken by user."
                        );
                        _alertTitle("Sorry");
                        setTimeout(() => {
                          _showAlert(true);
                        }, 100);
                      } else {
                        _alertText(
                          "Sorry, we could not find this user.  Try again.  It is possible that the user has not downloaded the app or signed up for an account yet."
                        );
                        _alertTitle("No User Found");
                        setTimeout(() => {
                          _showAlert(true);
                        }, 100);
                      }
                    }
                  }
                );
              }}
              color="secondary"
              expand="block"
              shape="round"
            >
              {action.button_text}
            </IonButton>
          </div>
        );
      });
    } else {
      if (vendor.actions && vendor.actions.actions) {
        return (
          <div style={{ textAlign: "center", paddingTop: 5 }}>
            <h4>There is no user setup for this code.</h4>
            This code must be claimed before it can complete actions.
            <br />
            {/* <IonButton>
             Claim it now?
            </IonButton> */}
          </div>
        );
      } else {
        return null;
      }
    }
  }

  function withTip(tip: any) {
    return _.round(
      _.add(
        (tip.percent / 100) * _.toNumber(transactionSub),
        _.toNumber(transactionSub)
      ),
      2
    );
  }

  function canTip() {
    let found = false;
    if (vendor && vendor.tips) {
      for (let tip of vendor.tips) {
        if (withTip(tip) <= _.divide(_.toNumber(scannedWallet.value), 100)) {
          found = true;
        }
      }
    }

    return found;
  }

  return (
    <IonPage>
      <IonHeader style={{ backgroundColor: "white" }}>
        <IonToolbar style={{ backgroundColor: "white" }}>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>
          <IonTitle>{vendor?.Name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isMobile == true ? (
          <>
            {scanning == true ? null : (
              <>
                {finished != true ? (
                  <>
                    {scannedWallet ? (
                      <>
                        {vendor?.canSeeWallet == true ? (
                          <div
                            style={{
                              position: "relative",
                              height: 200,
                              border: "2px dashed #ccc",
                              borderRadius: 8,
                              backgroundColor: "#eee",
                              textAlign: "center",
                              margin: 20,
                            }}
                          >
                            <div>This wallet has:</div>
                            <div
                              style={{
                                marginTop: 45,
                                fontSize: 40,
                                fontWeight: 900,
                              }}
                            >
                              ${(scannedWallet.value / 100).toFixed(2)}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 5,
                                width: "100%",
                              }}
                            >
                              <div>ID: {scannedWallet.id}</div>
                            </div>
                          </div>
                        ) : null}
                        {vendor?.canChargeWallet == true ? (
                          <div style={{ margin: 20 }}>
                            <div
                              style={{
                                marginTop: 5,
                                border: "1px #ccc solid",
                                borderRadius: 4,
                                backgroundColor: "#eee",
                              }}
                            >
                              <IonInput
                                placeholder="0.00"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "900",
                                  fontSize: 35,
                                }}
                                inputmode="decimal"
                                type="number"
                                onIonChange={(e) => {
                                  _transactionSub(e.detail.value);
                                }}
                              ></IonInput>
                            </div>
                            <div style={{ marginTop: 0, textAlign: "center" }}>
                              enter transaction subtotal above
                            </div>
                          </div>
                        ) : null}
                        {vendor?.canChargeWallet == true ? (
                          <>
                            {transactionSub &&
                            transactionSub <= scannedWallet.value / 100 ? (
                              <IonButton
                                onClick={() => {
                                  _showModal(true);
                                }}
                                style={{ margin: 20 }}
                                expand="block"
                                color="secondary"
                                shape="round"
                              >
                                Continue $
                                {_.toNumber(transactionSub).toFixed(2)}
                              </IonButton>
                            ) : (
                              <>
                                {transactionSub ? (
                                  <h4
                                    style={{
                                      textAlign: "center",
                                      color: "red",
                                    }}
                                  >
                                    not enough on wallet
                                  </h4>
                                ) : (
                                  <IonButton
                                    style={{ margin: 20 }}
                                    expand="block"
                                    color="secondary"
                                    shape="round"
                                    disabled
                                  >
                                    Enter Total Above To Continue
                                  </IonButton>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                        {scannedWallet &&
                        scannedWallet.owner &&
                        vendor.actions &&
                        vendor.actions.actions ? (
                          <div style={{ paddingTop: 15, textAlign: "center" }}>
                            <h2>Actions</h2>
                          </div>
                        ) : null}
                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                          {ListActions()}
                        </div>
                        <IonButton
                          style={{
                            marginTop: 100,
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                          onClick={() => {
                            history.goBack();
                          }}
                          color="warning"
                          expand="block"
                          shape="round"
                        >
                          Cancel
                        </IonButton>
                        <IonModal
                          isOpen={showModal}
                          cssClass="VendorScan_Modal"
                        >
                          <IonContent>
                            <div
                              style={{
                                marginTop: 100,
                                marginRight: 20,
                                marginLeft: 20,
                              }}
                            >
                              {selectedTip == undefined ? (
                                <>
                                  <div
                                    style={{
                                      fontSize: 30,
                                      textAlign: "center",
                                    }}
                                  >
                                    Subtotal
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 30,
                                      textAlign: "center",
                                      fontWeight: 900,
                                    }}
                                  >
                                    ${_.toNumber(transactionSub).toFixed(2)}
                                  </div>
                                  {canTip() ? (
                                    <div>
                                      <div
                                        style={{
                                          marginTop: 20,
                                          fontSize: 30,
                                          textAlign: "center",
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          marginLeft: 20,
                                          marginRight: 20,
                                        }}
                                      >
                                        {vendor && vendor.tips
                                          ? vendor.tips.map((tip: any) => {
                                              if (
                                                withTip(tip) <=
                                                _.divide(
                                                  _.toNumber(
                                                    scannedWallet.value
                                                  ),
                                                  100
                                                )
                                              ) {
                                                return (
                                                  <>
                                                    <IonButton
                                                      style={{
                                                        fontSize: 25,
                                                        fontWeight: 700,
                                                        marginTop: 30,
                                                      }}
                                                      onClick={() => {
                                                        _selectedTip(tip);
                                                      }}
                                                      expand="block"
                                                      color="secondary"
                                                      shape="round"
                                                    >
                                                      {tip.buttonText}
                                                    </IonButton>
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      $
                                                      {_.subtract(
                                                        withTip(tip),
                                                        transactionSub
                                                      ).toFixed(2)}
                                                    </div>
                                                  </>
                                                );
                                              } else {
                                                return null;
                                              }
                                            })
                                          : null}
                                      </div>
                                      <div style={{ marginTop: 10 }}>
                                        <IonButton
                                          style={{
                                            fontSize: 25,
                                            fontWeight: 700,
                                            margin: 20,
                                          }}
                                          onClick={() => {
                                            let tip = {
                                              buttonText: "No Tip",
                                              percent: 0,
                                            };
                                            _selectedTip(tip);
                                          }}
                                          expand="block"
                                          color="secondary"
                                          shape="round"
                                        >
                                          No Tip
                                        </IonButton>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      fontSize: 30,
                                      textAlign: "center",
                                    }}
                                  >
                                    Total
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 30,
                                      textAlign: "center",
                                      fontWeight: 900,
                                    }}
                                  >
                                    ${withTip(selectedTip).toFixed(2)}
                                  </div>
                                  <IonButton
                                    color="secondary"
                                    shape="round"
                                    style={{ marginTop: 100 }}
                                    onClick={() => {
                                      _finished(true);
                                      setTimeout(() => {
                                        _showFinish(true);
                                      }, 10000);
                                      let total = _.multiply(
                                        _.toNumber(
                                          withTip(selectedTip).toFixed(2)
                                        ),
                                        100
                                      );
                                      Db.Transaction(
                                        scannedWallet.id,
                                        vendor.wallet.id,
                                        total
                                      )
                                        .then((data) => {
                                          // alert("Success!!!");
                                        })
                                        .catch((err) => {
                                          alert("something may have failed");
                                        });

                                      // alert("approved");
                                    }}
                                    expand="block"
                                  >
                                    Finish & Pay
                                  </IonButton>
                                </>
                              )}
                            </div>
                            <IonButton
                              style={{ marginBottom: 30 }}
                              onClick={() => {
                                _selectedTip(undefined);
                                _showModal(false);
                              }}
                              color="secondary"
                              shape="round"
                            >
                              Cancel
                            </IonButton>
                          </IonContent>
                        </IonModal>
                      </>
                    ) : (
                      <IonButton
                        onClick={() => {
                          startScan();
                        }}
                        expand="block"
                        color="secondary"
                        shape="round"
                      >
                        Scan
                      </IonButton>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "40vh",
                        fontSize: 35,
                        fontWeight: 900,
                      }}
                    >
                      Success!!!
                    </div>
                    {showFinish == true ? (
                      <IonButton
                        expand="block"
                        onClick={() => {
                          history.goBack();
                        }}
                        size="small"
                        color="secondary"
                        shape="round"
                      >
                        Close
                      </IonButton>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <h2 style={{ textAlign: "center" }}>
            Feature only available on mobile
          </h2>
        )}
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          clearTimeout(alertTimeout);
          _showAlert(false);
          history.goBack();
        }}
        cssClass="my-custom-class"
        header={alertTitle}
        subHeader={""}
        message={alertText}
        buttons={["OK"]}
      />
    </IonPage>
  );
}

export default VendorScan;
