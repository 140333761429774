import {
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonInfiniteScroll,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { useEffect } from "react";
import {
  ICategory,
  ICategoryItem,
  IHeroImage,
  IHomeCategories,
  IPage,
} from "../../Interfaces";
import Db from "../../services/db";
import "./HomeCategories.css";
import moment from "moment";
import Card1 from "../Card1";

import Card3 from "../Card3";
import { useHistory } from "react-router";
import _ from "lodash";

interface ContainerProps {}

interface iParams {
  data: IHomeCategories;
}

export default function HomeCategories(params: iParams) {
  let history = useHistory();

  function IconList() {
    let index = 0;
    let cats = _.orderBy(params.data.categories);
    return cats.map((item: ICategory) => {
      //console.log("item", item);
      index++;
      return (
        <IonCol
          onClick={() => {
            history.push("/list/" + item.id, { list: item });
            console.log("LIST", item);
          }}
          key={index + "icon"}
          className="HomeCategories_item"
        >
          <IonRow key={index + "icon21"}>
            <IonCol className="HomeCategories_itemIcon">
              <IonIcon icon={Db.IconDictionary(item.icon)}></IonIcon>
            </IonCol>
          </IonRow>
          <IonRow key={index + "icon22"}>
            <IonCol className="HomeCategories_itemIconTitle">
              {item.name}
            </IonCol>
          </IonRow>
        </IonCol>
      );
    });
  }

  function CategoryList() {
    let index = 0;
    let cats = _.orderBy(params.data.categories);
    return cats.map((item: ICategory) => {
      //console.log("item", item);
      index++;
      return (
        <IonCol key={index + "col"} className="HomeCategories_categoryList">
          <IonRow key={index + "r1"}>
            <IonCol
              key={index + "c1"}
              onClick={() => {
                history.push("/list/" + item.id, { list: item });
                console.log("LIST", item);
              }}
              className="HomeCategories_categoryListTitle"
            >
              {item.name}
            </IonCol>
          </IonRow>
          <IonRow key={index + "r2"}>
            <IonCol key={index + "c2"}>
              <div className="HomeCategories_scrolling-wrapper">
                {ItemList(item)}
              </div>
            </IonCol>
          </IonRow>
          <IonRow key={index + "r3"}>
            <IonCol
              key={index + "c3"}
              style={{
                flex: 1,
                textAlign: "right",
                marginTop: -35,
                padding: 0,
              }}
            >
              <div
                onClick={() => {
                  history.push("/list/" + item.id, { list: item });
                  console.log("LIST", item);
                }}
                style={{
                  float: "right",
                  fontSize: 13,
                  padding: 5,
                  fontWeight: 800,
                }}
              >
                View More...
              </div>
            </IonCol>
          </IonRow>
        </IonCol>
      );
    });
  }

  function ItemList(category: ICategory) {
    let index = 0;
    let limit = 6;
    if (params.data.limit) {
      limit = params.data.limit;
    }

    let newitems = category.items;

    for (let i of category.linkedCategories) {
      i.type = "categories";
      newitems.push(i);
    }

    let newList: any = _.orderBy(
      _.shuffle(_.uniq(newitems)),
      ["startDate"],
      ["asc"]
    );

    //console.log("newList", newList);

    return newList.slice(0, limit).map((item: ICategoryItem) => {
      index++;
      //console.log("item", item);
      if (item.type == "categories") {
        return (
          <Card3
            key={index}
            item={item}
            clicked={() => {
              history.push("/list/"+ item.id, { list: item });
            }}
          />
        );
      } else {
        return (
          <Card1
            width="220px"
            key={index}
            item={item}
            clicked={() => {
              history.push("/profile", {
                item: item,
                related: category.items,
              });
            }}
          />
        );
      }
    });
  }

  return (
    <div className="HomeCategories_container">
      <IonGrid>
        {params.data.showIcons == false? null: (
          <IonRow key="1">{IconList()}</IonRow>
        )}
        <IonRow key="2">{CategoryList()}</IonRow>
      </IonGrid>
    </div>
  );
}
