import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItemSliding,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import {
  ICategoryItem,
  IHeroImage,
  IIconListItem,
  IPage,
} from "../../Interfaces";
import Db from "../../services/db";
import "./ProfileRelated.css";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import Card2 from "../Card2";
import { useHistory } from "react-router";
import _ from "lodash";
import { pencil, save } from "ionicons/icons";
import { useEffect, useState } from "react";

interface iParams {
  clicked: () => void;
  editing: boolean;
  item: any;
}

export default function ProfileEdit(params: iParams) {
  let history = useHistory();

  const [user, _user] = useState<any>();
  const [canEdit, _canEdit] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      let newuser: any = localStorage.getItem("user");
      //console.log("user", newuser);
      if (newuser && newuser.includes('"id"')) {
        newuser = JSON.parse(newuser);

        _user(newuser);
        console.log("USER", newuser);
        setTimeout(() => {
          if (newuser && newuser.editor) {
            checkIfEditor(newuser);
          }
        }, 200);
        console.log("checkIfEditor", canEdit);
      }
    }
  }, []);

  function checkIfEditor(theuser: any) {
    console.log("user.item", params.item.type);
    let found = false;
    if(theuser.groups){
      for(let g of theuser.groups){
        if(g.cms == true && g.edit == true){
          found = true
          _canEdit(true);
        }
      }
    }
    if (params.item.type == "places") {
      for (let edit of theuser.editor) {
        if (edit.canEdit && edit.places) {
          for (let place of edit.places) {
            console.log("place", place);
            if (place.id == params.item.id) {
              found = true;
              _canEdit(true);
            }
          }
        }
      }
    }
    if (params.item.type == "posts") {
      for (let edit of theuser.editor) {
        if (edit.canEdit == true && edit.posts) {
          for (let post of edit.post) {
            // console.log("place", post);
            if (post.id == params.item.id) {
              found = true;
              _canEdit(true);
            }
          }
        }
      }
    }
    if (params.item.type == "events") {
      for (let edit of theuser.editor) {
        if (edit.canEdit == true && edit.events) {
          for (let event of edit.events) {
            // console.log("place", event);
            if (event.id == params.item.id) {
              found = true;
              _canEdit(true);
            }
          }
        }
      }
    }

    return found;
  }

  return (
    <IonFab style={{ zIndex: 99999, bottom: 10, right: 10 }} slot="fixed">
      {user && canEdit ? (
        <>
          <IonFabButton
            onClick={() => {
              params.clicked();
            }}
          >
            {params.editing ? (
              <IonIcon icon={save} />
            ) : (
              <IonIcon icon={pencil} />
            )}
          </IonFabButton>
        </>
      ) : null}
    </IonFab>
  );
}
