import {
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonInfiniteScroll,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { useEffect } from "react";
import {
  ICategory,
  ICategoryItem,
  IHeroImage,
  IHomeCategories,
  IPage,
} from "../../Interfaces";
import Db from "../../services/db";
import "./CircleCategories.css";
import moment from "moment";
import Card1 from "../Card1";

import Card3 from "../Card3";
import { useHistory } from "react-router";
import _ from "lodash";

interface ContainerProps {}

interface iParams {
  data: any;
}

export default function CircleCategories(params: iParams) {
  let history = useHistory();

  function ItemList() {
    let index = 0;

    return params.data.categories.map((item: any) => {
      index++;
      return (
        <Card3
          key={index}
          item={item}
          clicked={() => {
            history.push("/list/" + item.id, { list: item });
          }}
        />
      );
    });
  }

  return (
    <div className="CircleCategories_container">
      <IonGrid>
        {params.data.showLabel == false? null : (
             <IonRow>
          <IonCol className="CircleCategories_categoryListTitle">
            {params.data.name}
          </IonCol>
        </IonRow>
        )}
     
        <IonRow>
          <div className="CircleCategories_scrolling-wrapper">
            <ItemList />
          </div>
        </IonRow>
      </IonGrid>
    </div>
  );
}
