import { IonGrid, IonRow, IonCol, IonIcon, IonItemSliding } from "@ionic/react";
import {
  ICategoryItem,
  IHeroImage,
  IIconListItem,
  IPage,
} from "../../Interfaces";
import Db from "../../services/db";
import "./ProfileRelated.css";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import Card2 from "../Card2";
import { useHistory } from "react-router";
import _ from "lodash";

interface iParams {
  list: any;
  item: any;
  clicked: (item: any) => void;
}

export default function ProfileRelated(params: iParams) {
  let history = useHistory();

  function List() {
    let index = 0;
    let newList: any = params.list;

    newList = _.filter(newList, (i) => {
      return i.id != params.item.id;
    });
    newList = _.shuffle(_.uniq(newList));
    newList = _.orderBy(newList, ["startDate"], ["asc"]);
    return newList
      .filter((x: any) => {
        if (x.startDate) {
          let now = moment();
          let end = moment(x.endDate);
          if (end >= now) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .filter((item: any) => {
        if (item.heroImage) {
          return true;
        } else {
          return false;
        }
      })
      .slice(0, 6)
      .map((item: any) => {
        index++;

        return (
          <Card2
            key={index}
            name={item.name}
            image={item.heroImage}
            resize={item.HeroImageResize}
            startDate={item.startDate}
            endDate={item.endDate}
            action={null}
            shortDescription={item.shortDescription}
            clicked={() => {
              params.clicked(item);
            }}
          />

        );
      });
  }
  return (
    <>
      <div className="ProfileRelated_container">
        <IonGrid>
          <IonRow>
            <IonCol>
              <List />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
