import {
  IonList,
  IonContent,
  IonItem,
  IonSlides,
  IonSlide,
  IonItemDivider,
} from "@ionic/react";
import { list, search } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import Card2 from "../Card2";
import * as _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";

import QRCode from "react-qr-code";
import Db from "../../services/db";

interface iParams {
  wallets: any;
  update:()=>void
}

export default function UserWallets(params: iParams) {

  const [city,_city] = useState<any>();

  let history = useHistory();

  useEffect(()=>{
    Db.City().then((data)=>{
      _city(data);
      console.log("userCity", data)
    })
  },[])

  const slideOpts = {
    on: {
      beforeInit() {
        const swiper: any = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.originalParams = Object.assign(
          swiper.originalParams,
          overwriteParams
        );
      },
      setTranslate() {
        const swiper: any = this;
        const { $, slides, rtlTranslate: rtl } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = slides.eq(i);
          let progress = $slideEl[0].progress;
          if (swiper.params.flipEffect.limitRotation) {
            progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
          }
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          const rotate = -180 * progress;
          let rotateY = rotate;
          let rotateX = 0;
          let tx = -offset$$1;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
            rotateX = -rotateY;
            rotateY = 0;
          } else if (rtl) {
            rotateY = -rotateY;
          }

          $slideEl[0].style.zIndex =
            -Math.abs(Math.round(progress)) + slides.length;

          if (swiper.params.flipEffect.slideShadows) {
            // Set shadows
            let shadowBefore = swiper.isHorizontal()
              ? $slideEl.find(".swiper-slide-shadow-left")
              : $slideEl.find(".swiper-slide-shadow-top");
            let shadowAfter = swiper.isHorizontal()
              ? $slideEl.find(".swiper-slide-shadow-right")
              : $slideEl.find(".swiper-slide-shadow-bottom");
            if (shadowBefore.length === 0) {
              shadowBefore = swiper.$(
                `<div class="swiper-slide-shadow-${
                  swiper.isHorizontal() ? "left" : "top"
                }"></div>`
              );
              $slideEl.append(shadowBefore);
            }
            if (shadowAfter.length === 0) {
              shadowAfter = swiper.$(
                `<div class="swiper-slide-shadow-${
                  swiper.isHorizontal() ? "right" : "bottom"
                }"></div>`
              );
              $slideEl.append(shadowAfter);
            }
            if (shadowBefore.length)
              shadowBefore[0].style.opacity = Math.max(-progress, 0);
            if (shadowAfter.length)
              shadowAfter[0].style.opacity = Math.max(progress, 0);
          }
          $slideEl.transform(
            `translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
          );
        }
      },
      setTransition(duration: any) {
        const swiper: any = this;
        const { slides, activeIndex, $wrapperEl } = swiper;
        slides
          .transition(duration)
          .find(
            ".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left"
          )
          .transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          // eslint-disable-next-line
          slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;

            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ["webkitTransitionEnd", "transitionend"];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      },
    },
  };

  function AvailableAtList(){
    if(city && city.Coin && city.Coin.places){
     return city.Coin.places.map((place:any)=>{
        return (
          <IonItem   
          onClick={()=>{
            place.type = "places"
            history.push("/profile", {
            item: place,
            related: city.Coin.places,
          });
          }} 
          >
            {place.name}
          </IonItem>
        )
      })

    }else{
      return null
    }
  }

  return (
    <>
      {params.wallets && params.wallets[0] && params.wallets[0].value != 0 ? (
        <>
          <IonSlides 
          style={{ height: 250 }} 
          pager={true} 
          options={slideOpts}
          onIonSlideTouchEnd={()=>{
            params.update()
          }}
          >

            <IonSlide
              style={{
                border: "2px #ddd dashed",
                borderRadius: 8,
                backgroundColor: "#eee",
              }}
            >
              <div
                style={{
                  margin: 10,
                  textAlign: "center",
                }}
              >
                <div> 
                  {city && city.Coin && city.Coin.logoCircle ? (
                    <img src={city.Coin.logoCircle.formats.thumbnail.url} style={{height:50}} />
                  ):null}
              
                </div>
                
                <div style={{ fontSize: 40, fontWeight: 900 }}>
                 
                  {_.toNumber(params.wallets[0].value).toFixed(
                    
                  )}
                </div>
                {city && city.Coin && city.Coin.Title ? (
                    city.Coin.Title
                  ):"Value"}
                <br />
                <i style={{ fontSize: "small" }}>swipe for code</i>
              </div>
            </IonSlide>
            <IonSlide
              style={{
                border: "2px #ddd dashed",
                borderRadius: 8,
                backgroundColor: "#eee",
              }}
            >
              <div
                style={{
                  margin: 10,
                  textAlign: "center",
                }}
              >
                <QRCode size={150} value={params.wallets[0].uuid} />
                <br />
                id: {params.wallets[0].id}
                <br />
                <i style={{ fontSize: "small" }}>swipe for value</i>
                <br />
              </div>
            </IonSlide>
          </IonSlides>
      
             {city && city.Coin && city.Coin.tos ? (
              <div style={{textAlign:"center",margin:10, fontSize:"small"}}>
              <a href={city.Coin.tos}>Terms</a>
            </div>
            
            ):"Value"}  
            <IonList style={{marginLeft:-10, marginRight:-10}}>
              <IonItemDivider>
                Accepted At
              </IonItemDivider>
              <AvailableAtList/> 
            </IonList>
        </>
      ) : null} 
    </>
   
  );
}
