import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonBackButton,
  IonButton,
  IonImg,
  IonInput,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { IHeroImage, IIconListItem, IPage } from "../../Interfaces";
import Db from "../../services/db";
import "./ProfileAction.css";
import { Geolocation } from "@capacitor/geolocation";
import { isTemplateExpression } from "typescript";
import { useHistory } from "react-router";
import _ from "lodash";
import ReactMarkdown from "react-markdown";

interface iParams {
  item: any;
  linkedClicked: (linked: any) => void;
}

export default function ProfileAction(params: iParams) {
  let history = useHistory();
  const [user, _user] = useState<any>();
  const [loading, _loading] = useState(false);
  const [current, _current] = useState<any>();
  useEffect(() => {
    if (localStorage.getItem("user")) {
      let user: any = localStorage.getItem("user");
      // console.log("user", user);
      if (user && user.includes('"id"')) {
        user = JSON.parse(user);
        _user(user);
        let hasRegion = false;
        if (params.item && params.item.actions && params.item.actions[0]) {
          for (let a of params.item.actions) {
            if (a.action_type && a.action_type == "region") {
              hasRegion = true;
            }
          }
          if (hasRegion == true) {
            console.log("param.item", params.item);
            checkCurrentPosition();
          }
        }
      }
    }
    Db.Me().then((me: any) => {
      if (me && me.id) {
        _user(me);
        //  console.log("me", me);
        localStorage.setItem("user", JSON.stringify(me));
        if (me.vendor != undefined) {
          Db.MyVendor().then((data2) => {
            console.log("myvendor", data2);
            localStorage.setItem("vendor", JSON.stringify(data2));
          });
        }
      }
    });
  }, []);

  function lessThanOneHourAgo(date: any) {
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = Date.now() - HOUR;

    return date > anHourAgo;
  }

  function calcDistance(coordinate1: any, coordinate2: any) {
    const toRadian = (n: number) => (n * Math.PI) / 180;
    let lat2 = coordinate2.lat;
    let lon2 = coordinate2.lon;
    let lat1 = coordinate1.lat;
    let lon1 = coordinate1.lon;
    let R = 6371; // km
    let x1 = lat2 - lat1;
    let dLat = toRadian(x1);
    let x2 = lon2 - lon1;
    let dLon = toRadian(x2);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) *
        Math.cos(toRadian(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d * 3280.84;
  }

  async function checkCurrentPosition() {
    _loading(true);

    if (localStorage.getItem("currentPosition")) {
      let localCurrent: any = await localStorage.getItem("currentPosition");
      //console.log("localCurrent", localCurrent);
      let oldCurrent = JSON.parse(localCurrent);
      if (
        oldCurrent &&
        oldCurrent.coords &&
        lessThanOneHourAgo(oldCurrent.timestamp)
      ) {
        // console.log("oldCurrent", oldCurrent);
        _current(oldCurrent);
        _loading(false);
      }
    }

    await Geolocation.checkPermissions().then(async (positionStatus) => {
      // console.log("checkPermissions");
      if (positionStatus.location == "granted") {
        getPosition();
      }
      if (positionStatus.location == "prompt") {
        await Geolocation.requestPermissions().then((requestStatus) => {
          if (requestStatus.location == "granted") {
            getPosition();
          }
        });
      }
      if (positionStatus.location == "prompt-with-rationale") {
        await Geolocation.requestPermissions().then((requestStatus) => {
          if (requestStatus.location == "granted") {
            getPosition();
          }
        });
      }
      if (positionStatus.location == "prompt-with-rationale") {
        await Geolocation.requestPermissions().then((requestStatus) => {
          if (requestStatus.location == "granted") {
            getPosition();
          }
        });
      }
    });
  }

  async function getPosition() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    await Geolocation.getCurrentPosition(options).then(
      (position: any) => {
        _current(position);
        _loading(false);

        let toBeStored = {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          },
          timestamp: position.timestamp,
        };

        //console.log("position", position);

        localStorage.setItem("currentPosition", JSON.stringify(toBeStored));
      },
      (error) => {
        alert(
          "Unable to get location. Please check app locations permissions in settings"
        );
      }
    );
  }

  async function checkPosition(action: any) {
    if (current) {
      let loc1 = {
        lat: action.action_region.latitude,
        lon: action.action_region.longitude,
      };
      let loc2 = {
        lat: current.coords.latitude,
        lon: current.coords.longitude,
      };
      let distance = calcDistance(loc1, loc2);
      // console.log("distance", distance);
      // console.log("action", loc1);
      // console.log("current", loc2);
      if (distance <= action.action_region.radius) {
        Db.TakeAction(action, action.points_earn, 0).then((data) => {
          // console.log("action taken", data);
          if (data.id) {
            _user(data);
            // console.log("me", data);
            localStorage.setItem("user", JSON.stringify(data));
          }
          _loading(false);
        });
      } else {
        alert(
          "You are not close enough to " +
            params.item.name +
            " please move closer"
        );
      }
    } else {
      checkCurrentPosition();
    }
  }

  function checkActionTaken(x: any) {
    let found = false;

       if (user && user.actions) {
      for (let y of user.actions) {
        if (y.action_id.includes(x.id)) {
          found = true;
        }
      }
    }

    return found;
  }

  function List() {
    return params.item.actions.map((action: any) => {
      let actionCode: any;
      console.log("action", action);
      return (
        <IonCol key={action.id} className="ProfileAction_col">
          {!checkActionTaken(action) && (action.action_type && action.action_type != "show_as_completed") ? (
            <div style={{ width: "100%" }}>
              {loading != true ? (
                <IonRow>
                     {action.action_type && action.action_type == "button" ? (
                    <IonCol
                      className="ProfileAction_button"
                      onClick={() => {
                        Db.ga.track("Action", { action: action.id });
                        Db.TakeAction(
                          action,
                          action.points_earn,
                          action.code
                        ).then((data) => {
                          // console.log("action taken", data);
                          if (data.id) {
                            _user(data);
                            // console.log("me", data);
                            localStorage.setItem(
                              "user",
                              JSON.stringify(data)
                            );
                          }
                          _loading(false);
                        });
                      }}
                    >
                      {action.button_text}
                    </IonCol>
                  ) : null}
                  {action.action_type && action.action_type == "region" ? (
                    <IonCol
                      className="ProfileAction_button"
                      onClick={() => {
                        Db.ga.track("Action", { action: action.id });
                        if (user) {
                          checkPosition(action);
                        } else {
                          history.replace("/user", {
                            list: {
                              id: 1,
                              Title: "Login",
                              page: null,
                              icon: "event_seat",
                              AppRoute: "user",
                            },
                          });
                        }
                      }}
                    >
                      {action.button_text}
                    </IonCol>
                  ) : null}
                  {action.action_type && action.action_type == "code" ? (
                    <>
                      <IonCol>
                        <IonRow>
                          <IonCol size="7">
                            <IonInput
                              placeholder={action.action_earn_text}
                              style={{
                                backgroundColor: "white",
                                border: "2px #ccc solid",
                                borderRadius: 8,
                              }}
                              value={actionCode}
                              onIonChange={(e) => {
                                actionCode = e.detail.value;
                              }}
                            />
                          </IonCol>
                          <IonCol
                            style={{ marginTop: 6 }}
                            className="ProfileAction_button"
                            onClick={() => {
                              if (user) {
                                if (
                                  _.trim(actionCode).toLocaleLowerCase() ==
                                  _.trim(action.code).toLocaleLowerCase()
                                ) {
                                  Db.TakeAction(
                                    action,
                                    action.points_earn,
                                    action.code
                                  ).then((data) => {
                                    // console.log("action taken", data);
                                    if (data.id) {
                                      _user(data);
                                      // console.log("me", data);
                                      localStorage.setItem(
                                        "user",
                                        JSON.stringify(data)
                                      );
                                    }
                                    _loading(false);
                                  });
                                } else {
                                  alert("Sorry, did not match.");
                                }
                              } else {
                                history.replace("/user", {
                                  list: {
                                    id: 1,
                                    Title: "Login",
                                    page: null,
                                    icon: "event_seat",
                                    AppRoute: "user",
                                  },
                                });
                              }
                            }}
                          >
                            {action.button_text}
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </>
                  ) : null}

                {action.linked && action.linked.displayButtonAlways ? (
                  <div
                    style={{ borderBottom: "3px #ccc solid", width: "100%" }}
                  >
                    <IonRow>
                   {action.action_type != "show_as_completed" ? (
                      <IonCol
                        style={{ marginTop: 16, marginBottom: 16  }}
                        className="ProfileAction_button"
                        onClick={() => {
                          //console.log(action.linked)
                          params.linkedClicked(action.linked);
                        }}
                      >
                        {action.linked.buttonText}
                      </IonCol>
                      ) : (
                        <IonCol
                        style={{ marginTop: 16, marginBottom: 16, border: "3px solid #4f4f4f"  }}
                        className="ProfileAction_button"
                        onClick={() => {
                          //console.log(action.linked)
                          params.linkedClicked(action.linked);
                        }}
                      >
                        {action.linked.buttonText}
                      </IonCol>
                      )}
                    </IonRow>
                  </div>
                ) : null}
                </IonRow>
              ) : (
                <>
                  <IonRow>
                    <IonCol className="ProfileAction_button_loading">
                      Loading...
                    </IonCol>
                  </IonRow>
                </>
              )}


            </div>
          ) : (
            <>
            { action.action_type != "show_as_completed" ? (
              <IonRow>
                <IonCol
                  className="ProfileAction_button_success"
                  onClick={() => {
                    alert(
                      params.item.name +
                        " has been " +
                        action.button_complete_text
                    );
                  }}
                >
                  {action.button_complete_text}
                </IonCol>
              </IonRow>
              ): null}
              <IonRow>
                {action.displayAudioOnComplete == true &&
                action.audioClipMp3 &&
                action.audioClipMp3.url ? (
                  <div style={{ width: "100%", paddingTop: 10 }}>
                    <audio
                      style={{ width: "100%" }}
                      controlsList="nodownload"
                      controls
                      src={action.audioClipMp3.url}
                    ></audio>
                  </div>
                ) : null}
                {action.displayImageOnComplete &&
                action.displayImageOnComplete == true &&
                action.image ? (
                  <div
                    style={{ borderBottom: "3px #ccc solid", width: "100%" }}
                  >
                    <IonRow>
                      <IonCol>
                        <IonImg
                          style={{ maxHeight: 200, width: "100%" }}
                          src={action.image.url}
                        />
                      </IonCol>
                    </IonRow>
                  </div>
                ) : null}
                {action.displayBodyOnComplete == true && action.body ? (
                  <div
                    style={{ borderBottom: "3px #ccc solid", width: "100%" }}
                  >
                    <IonRow>
                      <IonCol>
                        <ReactMarkdown>{action.body}</ReactMarkdown>
                      </IonCol>
                    </IonRow>
                  </div>
                ) : null}
                     {action.linked && action.linked.displayButtonOnComplete ? (
                  <div
                    style={{ borderBottom: "3px #ccc solid", width: "100%" }}
                  >
                    <IonRow>
                   {action.action_type != "show_as_completed" ? (
                      <IonCol
                        style={{ marginTop: 16, marginBottom: 16  }}
                        className="ProfileAction_button"
                        onClick={() => {
                          //console.log(action.linked)
                          params.linkedClicked(action.linked);
                        }}
                      >
                        {action.linked.buttonText}
                      </IonCol>
                      ) : (
                        <IonCol
                        style={{ marginTop: 16, marginBottom: 16, border: "3px solid #4f4f4f"  }}
                        className="ProfileAction_button"
                        onClick={() => {
                          //console.log(action.linked)
                          params.linkedClicked(action.linked);
                        }}
                      >
                        {action.linked.buttonText}
                      </IonCol>
                      )}
                    </IonRow>
                  </div>
                ) : null}
       
              </IonRow>
            </>
          )}
        </IonCol>
      );
    });
  }

  return (
    <IonCol>
      {params.item.actions ? (
        <IonRow>
          <List />
        </IonRow>
      ) : null}
    </IonCol>
  );
}
