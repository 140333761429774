import { IonCol, IonGrid, IonRow } from "@ionic/react";
import _ from "lodash";
import {
  IContactInfoBlock,
  IHeroImage,
  IHomeContactInfo,
} from "../../Interfaces";
import Db from "../../services/db";
import "./ContactInfo.css";

interface iParams {
  data: IHomeContactInfo;
}

export default function ContactInfo(params: iParams) {
  function List(items: Array<IContactInfoBlock>) {
    let index = 0;
    return items.map((item: IContactInfoBlock) => {
      index++;

      let newWebsite = item.link;
      if (!_.toLower(newWebsite).includes("http")) {
        newWebsite = "https://" + newWebsite;
      }
      return (
        <IonRow key={index}>
          <IonCol>
            <a
              target="_blank"
              style={{ textDecoration: "none", color: "unset" }}
              href={newWebsite}
            >
              <img
                src={
                  item.logo &&
                  item.logo.formats &&
                  item.logo.formats.thumbnail.url
                }
                className="ContactInfo_logo"
              ></img>
              <div className="ContactInfo_title">{item.Title}</div>
              <div className="ContactInfo_line1">{item.line1}</div>
              <div className="ContactInfo_line2">{item.line2}</div>
            </a>
          </IonCol>
        </IonRow>
      );
    });
  }

  return (
    <IonGrid className="ContactInfo_container">
      {params.data.ContactInfoBlock ? (
        <>{List(params.data.ContactInfoBlock)}</>
      ) : null}
    </IonGrid>
  );
}
