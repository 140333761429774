import * as React from "react";
import { useState, useEffect } from "react";
import ReactMapboxGl, {
  Layer,
  Feature,
  Marker,
  GeoJSONLayer,
} from "react-mapbox-gl";
import _ from "lodash";
import { useHistory } from "react-router";
import Config from "../config";
import * as MapboxGl from "mapbox-gl";
import { IonButton } from "@ionic/react";
import "mapbox-gl/dist/mapbox-gl.css";

interface IParams {
  onDismiss: () => void;
  isTour: boolean;
  height: string;
  list: any;
  zoom: number;
  showClose: boolean;
}

export function updateMapBoxIndex(item: any) {
  if (thisMap) {
    console.log("header box index", item);
    if (item && item.region && item.region.lon && item.region.lat) {
      thisMap.panTo([item.region.lon, item.region.lat]);
    }
  }
}

let thisMap: MapboxGl.Map;

export default function MapBox(params: IParams) {
  const [loading, _loading] = useState(true);
  const [theMap, _theMap] = useState<any>();
  const [viewport, setViewport] = useState({
    width: "100vw",
    height: "100vh",
    latitude: params.list.center.lat,
    longitude: params.list.center.lon,
    zoom: 12,
  });

  const Map = ReactMapboxGl({
    accessToken: Config.mapboxToken,
    trackResize: true,
  });

  const onLoaded = (map: MapboxGl.Map) => {
    map.resize();
    _loading(false);
  };

  let history = useHistory();

  function Markers() {
    let newList = params.list.items;

    newList = _.orderBy(
      _.uniq(params.list.items),
      ["order", "startDate", "name"],
      ["asc", "asc", "asc"]
    );

    let index = 0;
    if (params && params.list.items) {
      return newList
        .filter((item: any) => {
          return item.region && item.region.lat;
        })
        .map((item: any) => {
          index++;
          let heroImage = item.heroImage.url;
          if (
            item.heroImage.formats &&
            item.heroImage.formats &&
            item.heroImage.formats.thumbnail.url
          ) {
            heroImage = item.heroImage.formats.thumbnail.url;
          }
          item.image = heroImage;
          return (
            <Marker
              coordinates={[item.region.lon, item.region.lat]}
              key={index}
            >
              <div
                onClick={() => {
                  history.push("/profile", {
                    item: item,
                    related: params.list.items,
                    showMap: true,
                  });
                  params.onDismiss();
                }}
                style={{
                  backgroundImage: "url(" + item.image + ")",
                  color: "black",
                  width: 50,
                  height: 50,
                  marginTop: -30,
                  marginLeft: -30,
                  borderRadius: 30,
                  border:
                    item.actions &&
                    item.actions[0] &&
                    item.actions[0].completed == true
                      ? "4px solid green"
                      : "4px solid #000",
                  backgroundSize: "cover",
                }}
              ></div>
            </Marker>
          );
        });
    }
  }

  if (viewport && viewport.latitude) {
    return (
      <>
        {loading == true ? (
          <div
            style={{ width: "100vw", height: "100vh", backgroundColor: "#ccc" }}
          ></div>
        ) : null}
        <Map
          containerStyle={{
            height: params.height ? params.height : "100vh",
            width: "100vw",
          }}
          style={"mapbox://styles/mapbox/streets-v11"}
          onStyleLoad={(map) => {
            onLoaded(map);
            thisMap = map;
          }}
          center={[params.list.center.lon, params.list.center.lat]}
          zoom={[params.zoom]}
        >
          {params.list.line ? (
            <GeoJSONLayer
              style={{ zIndex: 99999 }}
              data={params.list.line}
              linePaint={{
                "line-color": "grey",
                "line-width": 7,
              }}
            />
          ) : null}
          {Markers()}

          <div
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              left: 10,
              bottom: 30,
              fontSize: "xx-large",
              color: "black",
            }}
          >
            {params.showClose ? (
              <IonButton
                color="secondary"
                shape="round"
                expand="block"
                onClick={() => params.onDismiss()}
              >
                Close Map
              </IonButton>
            ) : null}
          </div>
        </Map>
      </>
    );
  } else {
    return null;
  }
}
