import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Profile from "./pages/Profile";
import List from "./pages/List";
import UserProfile from "./pages/User";
import Maps from "./pages/Maps";
import Ads from "./pages/Ads";
import Lists from "./pages/Lists";
import FormNew from "./pages/FormNew";
import VendorScan from "./pages/VendorScan";

setupConfig({
  mode: "ios",
  animated: false,
});

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/list/:id">
          <List />
        </Route>
        <Route exact path="/user">
          <UserProfile />
        </Route>
        <Route exact path="/maps">
          <Maps />
        </Route>
        <Route exact path="/ads">
          <Ads />
        </Route>
        <Route exact path="/lists">
          <Lists />
        </Route>
        <Route exact path="/new">
          <FormNew />
        </Route>
        <Route exact path="/vendorScan">
          <VendorScan />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
