import { IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react";
import { useHistory } from "react-router";
import { IHeroImage, IIconListItem, IPage } from "../../Interfaces";
import Db from "../../services/db";
import "./IconsList.css";

interface iParams {
  data: IIconListItem;
}

export default function IconsList(params: iParams) {
  let history = useHistory();
  function List() {
    let pages = params?.data.Pages;
    //console.log("list", pages);
    return pages.map((item: IPage) => {
      //console.log("item", item);
      return (
        <IonCol
          onClick={() => {
            if (item.AppRoute == "list") {
              let listDetails = { id: item.List };

              history.push("/" + item.AppRoute, { list: listDetails });
            }  else if(item.AppRoute == "link"){
                window.open(item.link, '_system', 'location=yes'); return false;
            } else {
              history.push("/" + item.AppRoute, { list: item });
            }
          }}
          key={item.id}
          className="IconsList_item"
        >
          <IonRow>
            <IonCol className="IconsList_itemIcon">
              <IonIcon icon={Db.IconDictionary(item.icon)}></IonIcon>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="IconsList_itemIconTitle">{item.Title}</IonCol>
          </IonRow>
        </IonCol>
      );
    });
  }

  return (
    <>
      <div className="IconList_container">
        <IonGrid>
          <IonRow>
            <List />
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
