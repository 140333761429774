import {
  IonList,
  IonContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import { list, search } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import Card2 from "../Card2";
import * as _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";
import Db from "../../services/db";

interface iParams {
  user: any;
  update:() => void;
}

export default function UserRedeem(params: iParams) {
  const [code, _code] = useState<any>();
  return (
    <>
      {params.user ? (
        <div
          style={{
           
            textAlign: "center",
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol style={{textAlign:"center"}} >
          
                  <IonInput
                   style={{marginBottom:15}}
                    className="NewForm_input"
                    value={code}
                    placeholder="Enter Code"
                    onIonChange={(e) => _code(e.detail.value)}
                  ></IonInput>
             
                <IonButton 
                style={{ width: 200 }} 
                shape="round" 
                color="secondary" 
                size="small" 
                onClick={()=>{
                  Db.RedeemWallet(code).then((data)=>{
                    if(data && data.code){
                      params.update();
                      _code("")
                    }else{
                      alert("Code not found");
                      
                    }
                  },(error)=>{
                    alert(error)
                  })
                }}
                >
                  Redeem
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : null}
    </>
  );
}
