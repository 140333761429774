import { IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react";
import { link, shareOutline } from "ionicons/icons";
import { IHeroImage, IIconListItem, IPage } from "../../Interfaces";
import { Share } from '@capacitor/share';
import Db from "../../services/db";
import ProfileAction from "./ProfileAction";
import "./ProfileIconsList.css";
import moment from "moment";

interface iParams {
  icons: any;
  item: any;
  linkedClicked:(linked:any)=>void
}

export default function ProfileIconsList(params: iParams) {
  function List() {
    let icons = params?.icons;
    //console.log("list", icons);
    let x = 0;
    return icons.map((item: any) => {
      x++;
      // console.log("item", item);

      return (
        <IonCol key={x} className="ProfileIconsList_item">
          <IonRow>
            <IonCol className="ProfileIconsList_itemIcon">
              <a
                target="_blank"
                style={{ textDecoration: "none", color: "unset" }}
                href={item.link}
              >
                <IonIcon icon={Db.IconDictionary(item.icon)}></IonIcon>
              </a>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ProfileIconsList_itemIconTitle">
              <a
                style={{
                  textAlign: "center",
                  textDecoration: "none",
                  color: "unset",
                }}
                href={item.link}
                target="_blank"
              >
                {item.Title}
              </a>
            </IonCol>
          </IonRow>
        </IonCol>
      );
    });
  }

  return (
    <>
      <div className="ProfileIconsList_container">
        <IonGrid>
          <IonRow>
            {params.icons ? <List /> : null} 
      <IonCol onClick={()=>{
         if(params.item.type == "events"){
            Share.share({
                      title: params.item.name,
                      text: params.item.name + " - " + moment(params.item.startDate).format("MMM Do") ,
                      url: params.item.website ? params.item.website : null,
                      dialogTitle: 'Share',
                    });
         }else{
            Share.share({
              title: params.item.name,
              text: params.item.name + " - " + params.item.shortDescription + " ",
              url: params.item.website ? params.item.website : null,
              dialogTitle: 'Share',
            });

         }
        
      }} key="share" className="ProfileIconsList_item">
          <IonRow>
            <IonCol className="ProfileIconsList_itemIcon">
                <IonIcon icon={shareOutline}></IonIcon>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ProfileIconsList_itemIconTitle">
              Share
            </IonCol>
          </IonRow>
        </IonCol>
          </IonRow>
          {params.item && params.item.actions ? (
            <IonRow>
              <ProfileAction item={params.item} linkedClicked={(linked)=>{

                //alert("clicked")
                params.linkedClicked(linked)
                
              }} />
            </IonRow>
          ) : null}
        </IonGrid>
      </div>
    </>
  );
}
