import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { IHeroImage, IHomeSocial, ISocialLink } from "../../Interfaces";
import Db from "../../services/db";
import { logoFacebook, logoTwitter, logoInstagram } from "ionicons/icons";
import "./SocialIcons.css";

interface ContainerProps {}

interface iParams {
  data: IHomeSocial;
}

export default function SocialIcons(params: iParams) {
  function List() {
    let index = 0;
    return params.data.HomeSocialIcon.map((item: ISocialLink) => {
      index++;
      return (
        <IonCol
          key={index}
          className="SocialIcons_icon"
          style={{ textAlign: "center" }}
        >
          <a
            style={{ color: "unset", textDecoration: "none" }}
            href={item.link}
          >
            {item.icon == "facebook" ? (
              <IonIcon icon={logoFacebook}></IonIcon>
            ) : null}
            {item.icon == "twitter" ? (
              <IonIcon icon={logoTwitter}></IonIcon>
            ) : null}
            {item.icon == "instagram" ? (
              <IonIcon icon={logoInstagram}></IonIcon>
            ) : null}
          </a>
        </IonCol>
      );
    });
  }
  return (
    <div className="SocialIcons_container">
      <IonGrid>
        <IonRow>
          <List />
        </IonRow>
      </IonGrid>
    </div>
  );
}
