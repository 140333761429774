import { useEffect, useState } from "react";
import { ICategoryItem } from "../Interfaces";
import "./Card1.css";
import moment from "moment";
import { RouteComponentProps, useHistory } from "react-router";
import Db from "../services/db";

interface ContainerProps {}

interface iParams {
  item: ICategoryItem;
  clicked: () => void;
  width: string;
}

export default function Card1(params: iParams) {
  let history = useHistory();

  const [newSrc, _newSrc] = useState<any>();

  useEffect(() => {
    if (params.item.heroImage) {
      let image = params.item.heroImage.url;
      if (params.item.heroImage && params.item.heroImage.formats) {
        image = params.item.heroImage.formats.thumbnail.url;
      }
      Db.getSrc(image).then((data) => {
        _newSrc(data);
      });
    }
  }, []);

  if (newSrc) {
    return (
      <div
        onClick={() => {
          params.clicked();
        }}
        className="Card1_card"
        style={{
          width: params.width ? params.width : 220,
          backgroundImage: "url(" + newSrc + ")",
          backgroundSize: params.item.HeroImageResize ? "cover" : "cover",
        }}
      >
        <div className="Card1_cardTitleBlock">
          <div className="Card1_cardTitleContent">
            <div className="Card1_cardTitleBlockTitle">
              {params.item.name}

              {params.item.startDate ? (
                <span style={{ fontSize: 11 }}>
                  <br />
                  {moment(params.item.startDate).format("M/D/YY")} -
                  {moment(params.item.endDate).format("M/D/YY")}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (params.item.heroImage) {
      return (
        <div
          className="Card1_card"
          style={{
            width: params.width ? params.width : 220,
          }}
        >
          <div className="Card1_cardTitleBlock">
            <div className="Card1_cardTitleContent">
              <div
                style={{ paddingTop: 14 }}
                className="Card1_cardTitleBlockTitle"
              ></div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
