import React, { useEffect, useState } from "react";
import { IonModal, IonButton, IonContent } from "@ionic/react";
import SimpleMap from "../GoogleMaps";
import MapBox from "../MapBox";

interface IParams {
  items: any;
  showModal: boolean;
  onDismiss: () => void;
  isTour: boolean;
  zoom: number;
  center: { lat: number; lon: number };
  list: any;
}

export default function ListMap(params: IParams) {
  useEffect(() => {}, []);

  return (
    <IonContent>
      <IonModal isOpen={params.showModal} cssClass="ListMap_container">
        <div
          style={{ backgroundColor: "#fff", height: "100vh", width: "100vw" }}
        >
          <div
            style={{
              position: "absolute",
              top: "50vh",
              left: "0",
              width: "100%",
              textAlign: "center",
            }}
          >
            Loading Map...
          </div>
          <MapBox
            height="100vh"
            list={params.list}
            showClose={true}
            onDismiss={() => {
              params.onDismiss();
            }}
            isTour={true}
            zoom={params.zoom}
          />
        </div>
      </IonModal>
    </IonContent>
  );
}
