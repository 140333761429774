import { ICategoryItem, IHeroImage } from "../../Interfaces";
import Db from "../../services/db";
import "./ProfileHeroImage.css";
import { CreateAnimation, Animation } from "@ionic/react";
import { useEffect, useState } from "react";
import moment from "moment";

interface ContainerProps {}

interface iParams {
  item: ICategoryItem;
}

export default function ProfileHeroImage(params: iParams) {
  const [newSrc, _newSrc] = useState<any>();
  let heroImage = "";
  if (
    params.item.heroImage &&
    params.item.heroImage.formats &&
    params.item.heroImage.formats.medium
  ) {
    heroImage = params.item.heroImage.formats.medium.url;
    Db.getSrc(heroImage).then((data) => {
      _newSrc(data);
    });
  } else {
    heroImage = params.item.heroImage.url;
    Db.getSrc(heroImage).then((data) => {
      _newSrc(data);
    });
  }
  if (newSrc) {
    return (
      <div className="ProfileHeroImage_heroImageContainer">
        <div
          className="ProfileHeroImage_heroImageContainerDiv"
          style={{
            backgroundImage: "url(" + newSrc + ")",
            backgroundSize: params.item.HeroImageResize
              ? params.item.HeroImageResize
              : "cover",
          }}
        >
          <div className="ProfileHeroImage_titleText">{params.item.name}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ProfileHeroImage_heroImageContainer">
        <div className="ProfileHeroImage_heroImageContainerDiv">
          <div className="ProfileHeroImage_titleText"></div>
        </div>
      </div>
    );
  }
}
