import { useEffect, useState } from "react";
import { ICategoryItem } from "../Interfaces";
import "./Card3.css";
import moment from "moment";
import { RouteComponentProps, useHistory } from "react-router";
import Db from "../services/db";

interface ContainerProps {}

interface iParams {
  item: any;
  clicked: () => void;
}

export default function Card3(params: iParams) {
  let history = useHistory();

  const [newSrc, _newSrc] = useState<any>();
  let image = params.item.image.url;
  if (params.item.image && params.item.image.formats) {
    image = params.item.image.formats.thumbnail.url;
  }
  Db.getSrc(image).then((data) => {
    _newSrc(data);
  });

  if (newSrc) {
    return (
      <div
        onClick={() => {
          params.clicked();
        }}
        className="Card3_card"
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: params.item.HeroImageResize ? "cover" : "cover",
        }}
      >
        <div className="Card3_cardTitleBlock">
          <div className="Card3_cardTitleContent">{params.item.name}</div>
        </div>
      </div>
    );
  } else {
    if (params.item.image) {
      return (
        <div className="Card3_card">
          <div className="Card3_cardTitleBlock">
            <div className="Card3_cardTitleContent"></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
