import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonRow,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBack,
  arrowBackCircle,
  ellipseOutline,
  list,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../components/BackButton";
import ImageSlide from "../components/ImageSlide";
import Card2 from "../components/Card2";
import Db from "../services/db";
import "./Maps.css";
import _ from "lodash";
import { ICategory } from "../Interfaces";

function Lists() {
  const [maps, _maps] = useState();
  const state: any = useLocation().state;
  const [showModal, _showModal] = useState(false);
  const [modalItem, _modalItem] = useState<any>();
  let history = useHistory();

  useEffect(() => {
    console.log("MAPS LIST", state.list);
    Db.Categories().then((data: any) => {
      console.log("maps", data);
      _maps(data);
      Db.ga.page("Lists");
    });
  }, []);

  function List({ items }: any) {
    let index = 1;

    return items
      .filter((item: any) => {
        return item.showMenu;
      })
      .map((item: any) => {
        let listItems: any = [];
        for (let i of item.linkedEvents) {
          i.type = "events";
          listItems.push(i);
        }
        for (let i of item.linkedPlaces) {
          i.type = "places";
          listItems.push(i);
        }
        for (let i of item.linkedPosts) {
          i.type = "posts";
          listItems.push(i);
        }

        item.items = listItems;
        index++;

        return (
          <>
            <Card2
              key={index}
              name={item.name}
              image={item.image}
              resize="cover"
              action={null}
              startDate=""
              endDate=""
              shortDescription={item.description}
              clicked={() => {
                //alert("clicked");
                //_showModal(true);
                // _modalItem(item);
                console.log("listItems", item);
                history.replace("/list/" + item.id, { list: item });
              }}
            />
          </>
        );
      });
  }

  return (
    <IonPage style={{ backgroundColor: "#f7f7f7" }}>
      {maps && state && state.list ? (
        <>
          <IonHeader translucent={true}>
            <IonToolbar>
              <IonButtons slot="start">
                <BackButton />
              </IonButtons>
              <IonTitle>{state.list.Title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <List items={maps} />
            </IonList>
          </IonContent>
        </>
      ) : null}
    </IonPage>
  );
}

export default Lists;
