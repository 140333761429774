import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../components/BackButton";
import ImageModal from "../components/ImageModal";
import Card2 from "../components/Card2";
import Db from "../services/db";
import "./Maps.css";

function Ads() {
  const [maps, _maps] = useState();
  const state: any = useLocation().state;
  const [showModal, _showModal] = useState(false);
  const [modalItem, _modalItem] = useState<any>();
  let history = useHistory();

  useEffect(() => {
    Db.ga.page("Lists");
    console.log("MAPS LIST", state.list);
    Db.Ads().then((data: any) => {
      console.log("maps", data);
      _maps(data);
    });
  }, []);

  function List({ items }: any) {
    let x = 1;

    return items.map((item: any) => {
      x++;

      return (
        <>
          <Card2
            key={x}
            name={item.title}
            image={item.image}
            resize="cover"
            startDate=""
            endDate=""
            action={null}
            shortDescription={item.shortDescription}
            clicked={() => {
              //alert("clicked");
              _showModal(true);
              _modalItem(item);
            }}
          />
        </>
      );
    });
  }

  return (
    <IonPage>
      {maps && state && state.list ? (
        <>
          <IonHeader style={{ backgroundColor: "white" }}>
            <IonToolbar style={{ backgroundColor: "white" }}>
              <IonButtons slot="start">
                <BackButton />
              </IonButtons>
              <IonTitle>{state.list.Title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent style={{ backgroundColor: "#f7f7f7" }}>
            <IonList>
              <List items={maps} />
            </IonList>
          </IonContent>
          {modalItem ? (
            <ImageModal
              showModal={showModal}
              title={modalItem.title}
              image={modalItem.image}
              link={modalItem.url ? modalItem.url : null}
              place={modalItem.place ? modalItem.place : null}
              onShowModal={(data) => {
                _showModal(data);
              }}
            />
          ) : null}
        </>
      ) : null}
    </IonPage>
  );
}

export default Ads;
