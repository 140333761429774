import React from "react";
import * as _ from "lodash";
import moment from "moment";
import axios from "axios";
import * as Icons from "ionicons/icons";
import Config from "../config";
import { register } from "../serviceWorkerRegistration";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Device } from '@capacitor/device';
import { setupCache } from 'axios-cache-adapter'

import OneSignal from 'onesignal-cordova-plugin';
import { config } from "process";
// Working on getting back on maser --  I did it!!!

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 2000,
  exclude: {
    query: false
  }
})

const CACHE_FOLDER = "CACHED-IMG";
const appKey = "oavdci99rv41e2qq2at6lch41gt25vo";

// Create `axios` instance passing the newly created `cache.adapter`
const axiosCached = axios.create({
  adapter: cache.adapter
})

const Db = {
  social: Config.social,
  host: Config.host,
  oneSignalAppId: Config.oneSignalAppId,
  headers: {
    'Content-Type': 'application/json'
  },
  ga: {
    page(page, options) {
      console.log("ga", page)
      window.analytics.page(page, { app: Config.Segment, ...options })
      Db.Track("page", page, options)
    },
    track(event, options) {
      window.analytics.track(event, { app: Config.Segment, ...options })
      Db.Track("action", event, options)
    }
  },
  async Track(type, key = "action", meta = null, refId = null) {
    let url = this.host + "/analytics/track"
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    let details = [];
    if (typeof meta === Object) {
      for (let [key, value] of Object.entries(meta)) {
        details.push({
          key,
          value
        })
      }
    }

    if (key == "AppLoaded" || key == "UserLogin" || key == "UserSignup") {
      for (let [key, value] of Object.entries(deviceInfo)) {
        details.push({
          key,
          value
        })
      }
      let userJson = await localStorage.getItem("user");
      if (userJson) {
        let user = JSON.parse(userJson);
        details.push({
          key: "userId",
          value: user.id
        })
      }

    }


    return await axiosCached.post(url,
      {
        appKey,
        type,
        refId,
        key,
        deviceId: deviceId.uuid,
        details
      }, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async City() {
    let url = this.host + "/city"
    return await axiosCached.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Config() {
    let url = this.host + "/config"
    return await axiosCached.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async DraftEvent(event, image) {

    let url = this.host + "/events/draft"
    return await axiosCached.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async DraftPlace(event, image) {

    let url = this.host + "/events/draft"
    return await axiosCached.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async HomeLayout() {
    let url = this.host + "/city/home"


    return await axiosCached.get(url, {
      headers: this.headers,
    }).then(async (response) => {

      return response.data


    }).catch(err => {
      // alert(err)
    });
  },

  async Auth(email, password) {
    email = _.trim(email);
    let payload = {
      identifier: email,
      password: password
    }
    return await axios.post(this.host + "/auth/local", payload, {
      headers: this.headers,

    }).then((response) => response.data).catch(err => {
      alert("Invalid email or password,  try resetting your password")
    });
  },
  async Register(email, password, name) {
    email = _.trim(email);
    let payload = {
      username: email,
      name: name,
      email: email,
      password: password
    }
    return await axios.post(this.host + "/auth/local/register", payload, {
      headers: this.headers,
    }).then((response) => {
      console.log("response", response.data)
      return response.data
    }).catch(error => {
      if (error.response) {
        console.log(error.response.data);
        if (error.response.data.message) {
          for (let a of error.response.data.message) {
            for (let b of a.messages) {
              alert(b.message)
            }
          }
        }
      }
    });
  },
  async Maps() {
    return await axiosCached.get(this.host + "/maps", {
      headers: this.headers,
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async Ads() {
    return await axiosCached.get(this.host + "/coupons", {
      headers: this.headers,
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async Categories() {
    return await axiosCached.get(this.host + "/categories", {
      headers: this.headers,
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async Category(id, shouldAuth) {
    let theHeaders = this.headers
    if (localStorage.getItem("jwt") && shouldAuth != false) {
      let token = await localStorage.getItem("jwt")
      theHeaders = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    }
    return await axiosCached.get(this.host + "/categories/" + id, {
      timeout: 10000,
      headers: theHeaders,
    }).then((response) => response.data).catch(err => {

    });
  },
  async ItemDetails(item) {
    return await axiosCached.get(this.host + "/" + item.type + "/" + item.id, {
      headers: this.headers,
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async Me() {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.get(this.host + "/users/me", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async RedeemWallet(code) {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.post(this.host + "/transactions/redeem", { code }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async MyWallets() {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.get(this.host + "/wallets/me", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async MyVendor() {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.get(this.host + "/vendors/me", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async Wallet(uuid) {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.get(this.host + "/wallets?uuid=" + uuid, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async TakeAction(action, value, code) {
    let body = {
      action_id: action.id,
      points_earn: action.points_earn,
      code: code ? code : null,
      status: action.defaultStatus ? action.defaultStatus : null
    }

    let token = await localStorage.getItem("jwt")

    return await axiosCached.put(this.host + "/actions/take", body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },
  async VendorTakeAction(action_id, email) {
    let body = {
      email: email,
      action_id: action_id,
      points_earn: 0,
    }

    let token = await localStorage.getItem("jwt")

    return await axiosCached.put(this.host + "/actions/vendorTake", body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },
  async Transaction(from, to, value) {
    let body = {
      from,
      to,
      value
    }

    let token = await localStorage.getItem("jwt")

    return await axiosCached.post(this.host + "/transactions", body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },

  async Editor(item) {
    let token = await localStorage.getItem("jwt")
    return await axiosCached.post(this.host + "/" + item.type + "/editor", item, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },
  async Upload(file, type, field, id) {
    let theId = id;
    if (_.includes(id, "-")) {
      theId = id.split("-")[1];
    }

    let photo = file;
    let formData = new FormData();

    formData.append("files", photo, photo.name);
    formData.append("ref", type);
    formData.append("field", field);
    formData.append("refId", theId);

    let token = await localStorage.getItem("jwt")
    return await axiosCached.post(this.host + "/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token,
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },
  async Actions() {
    return await axiosCached.get(this.host + "/actions", {
      headers: this.headers,
    }).then((response) => response.data).catch(err => {
      // alert(err)
    });
  },
  async UserForgot(email) {

    let payload = {
      "email": email
    }
    axios
      .post(this.host + '/auth/forgot-password', payload)
      .then(response => {
        console.log(response);
      })
      .catch(error => {

        // console.log('An error occurred:', error.response.data.message);
        if (error.response && error.response.data && error.response.data.message) {
          for (let m of error.response.data.message) {
            console.log(m.messages)
            for (let m2 of m.messages) {
              alert(m2.message)
            }
          }
        }
      });
  },
  IconDictionary(icon) {
    if (icon == "account_balance") return Icons.wallet;
    else if (icon == "account_balance_wallet") return Icons.cash;
    else if (icon == "anchor") return Icons.linkOutline;
    else if (icon == "announcement") return Icons.volumeHigh;
    else if (icon == "article") return Icons.list;
    else if (icon == "beach_access") return Icons.codeDownload;
    else if (icon == "bookmarks") return Icons.bookmark;
    else if (icon == "card_giftcard") return Icons.card;
    else if (icon == "commute") return Icons.car;
    else if (icon == "credit_card") return Icons.card;
    else if (icon == "departure_board") return Icons.menu;
    else if (icon == "design_services") return Icons.create;
    else if (icon == "directions_bike") return Icons.bicycle;
    else if (icon == "directions_boat") return Icons.boat;
    else if (icon == "directions_bus") return Icons.bus;
    else if (icon == "directions_car") return Icons.car;
    else if (icon == "directions_railway") return Icons.train;
    else if (icon == "directions_run") return Icons.walk;
    else if (icon == "directions_walk") return Icons.walk;
    else if (icon == "event") return Icons.calendar;
    else if (icon == "event_seat") return Icons.calendar;
    else if (icon == "explore") return Icons.compass;
    else if (icon == "fastfood") return Icons.pizza;
    else if (icon == "favorite") return Icons.star;
    else if (icon == "flight") return Icons.airplane;
    else if (icon == "flight_takeoff") return Icons.airplane;
    else if (icon == "golf_course") return Icons.flag;
    else if (icon == "handyman") return Icons.hammer;
    else if (icon == "home") return Icons.home;
    else if (icon == "hotel") return Icons.bed;
    else if (icon == "label") return Icons.browsers;
    else if (icon == "local_activity") return Icons.calendar;
    else if (icon == "local_bar") return Icons.beer;
    else if (icon == "local_cafe") return Icons.cafe;
    else if (icon == "local_dining") return Icons.restaurant;
    else if (icon == "local_gas_station") return Icons.car;
    else if (icon == "local_pizza") return Icons.pizza;
    else if (icon == "local_see") return Icons.eye;
    else if (icon == "loyalty") return Icons.heart;
    else if (icon == "map") return Icons.map;
    else if (icon == "masks") return Icons.bookmark;
    else if (icon == "menu_book") return Icons.book;
    else if (icon == "person") return Icons.person;
    else if (icon == "pets") return Icons.paw;
    else if (icon == "place") return Icons.pin;
    else if (icon == "pool") return Icons.water;
    else if (icon == "phone") return Icons.call;
    else if (icon == "receipt") return Icons.bookmark;
    else if (icon == "redeem") return Icons.archive;
    else if (icon == "restaurant") return Icons.restaurant;
    else if (icon == "sailing") return Icons.boat;
    else if (icon == "schedule") return Icons.calendar;
    else if (icon == "set_meal") return Icons.restaurant;
    else if (icon == "shopping_bag") return Icons.briefcase;
    else if (icon == "shopping_basket") return Icons.basket;
    else if (icon == "shopping_cart") return Icons.cart;
    else if (icon == "spa") return Icons.water;
    else if (icon == "sports_bar") return Icons.beer;
    else if (icon == "star") return Icons.star;
    else if (icon == "store") return Icons.business;
    else if (icon == "storefront") return Icons.business;
    else if (icon == "tapas") return Icons.restaurant;
    else if (icon == "terrain") return Icons.image;
    else if (icon == "today") return Icons.calendar;
    else if (icon == "tour") return Icons.camera;
    else if (icon == "traffic") return Icons.car;
    else if (icon == "theaters") return Icons.film;
    else if (icon == "wine_bar") return Icons.wine;
    else if (icon == "usd") return Icons.logoUsd;
    else return Icons.grid;
  },
  async getSrc(src) {
    const imageName = src.split("/").pop();
    const fileType = imageName.split(".").pop();

    return await Filesystem.readFile({
      directory: Directory.Cache,
      path: CACHE_FOLDER + "/" + imageName,
    })
      .then((readFile) => {
        return `data:image/` + fileType + ";base64," + readFile.data;
      })
      .catch(async (e) => {
        //Write the file
        return await this.storeImage(src, CACHE_FOLDER + "/" + imageName).then(
          (data) => {
            return `data:image/` + fileType + ";base64," + data;
          }
        );
      });
  },
  async storeImage(url, path) {
    return await this.getBase64(url).then(async (base64Data) => {
      //console.log("response", base64Data);
      await Filesystem.writeFile({
        path: path,
        data: base64Data,
        directory: Directory.Cache,
        recursive: true
      });
      return base64Data;
    });
  },
  getBase64(url) {
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      );
  },
  InitOnsignal() {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);

    // ignore if not a device
    if (!window.cordova) return;
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId(this.oneSignalAppId);
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });
  }
};

export default Db;
