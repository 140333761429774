import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./FormNew.css";
import { useHistory, useLocation, useParams } from "react-router";

import Db from "../services/db";
import { arrowBackCircle } from "ionicons/icons";

function FormNew() {
  const [city, _city] = useState<any>();
  const [description, _description] = useState<any>();
  const [address1, _address1] = useState<any>();
  const [address2, _address2] = useState<any>();
  const [cityField, _cityField] = useState<any>();
  const [type, _type] = useState<any>();
  const [state, _state] = useState<any>();
  const [zip, _zip] = useState<any>();
  const [phone, _phone] = useState<any>();
  const [website, _website] = useState<any>();
  const [EndDate, _EndDate] = useState<any>();
  const [StartDate, _StartDate] = useState<any>();
  const [name, _name] = useState<any>();

  const [user, _user] = useState<any>();
  const [heroImage, _heroImage] = useState<any>();
  const [submitted, _submitted] = useState(false);
  const [contactName, _contactName] = useState<any>();
  const [contactEmail, _contactEmail] = useState<any>();
  let history = useHistory();

  let urlParams: any = useParams();

  useEffect(() => {
    let localCity: any = localStorage.getItem("data:city");
    _city(JSON.parse(localCity));
    console.log(city);
  }, []);

  useIonViewDidEnter(()=>{
    let userJson:any = localStorage.getItem("user")
    let userLocal = JSON.parse(userJson);


    if(!userLocal || !userLocal.id){
      
      history.push("/user")
    }else{
      _user(userLocal)
    }
  })

  return (
    <IonPage>
      {city && city.name ? (
        <IonContent>
          <h2
            style={{
              textAlign: "center",
              paddingBottom: 10,
              paddingTop: 40,
            }}
          >
            {city.name}
          </h2>
          <div style={{ position:"absolute", top: 54, left:10,  }}>
                     <IonIcon
        onClick={() => {
          history.goBack();
          console.log("BACK");
        }}
        icon={arrowBackCircle}
        size="large"
      ></IonIcon>
                      </div>
          {submitted != true ? (
            <>
              <h4 style={{ textAlign: "center" }}>Submit Something</h4>
              <div style={{ width: "calc(100vw - 40px)", margin: 20 }}>
                <div className="NewForm_input_container">
                  <b>Type *</b>
                  <IonSelect
                    value={type}
                    onIonChange={(e) => {
                      _type(e.detail.value);
                    }}
                    className="NewForm_input"
                  >
                    <IonSelectOption value="events">Event</IonSelectOption>
                    <IonSelectOption value="places">Place</IonSelectOption>
                  </IonSelect>
                </div>
                {type ? (
                  <>
                    {type == "events" ? (
                      <>
                        <div className="NewForm_input_container">
                          <b>Start Date</b>
                          <IonDatetime
                            displayFormat="MMM DD, YYYY HH:mm"
                            style={{
                              border: "1px #ccc solid",
                              borderRadius: 5,
                              backgroundColor: "white",
                            }}
                            onIonChange={(e) => {
                              _StartDate(e.detail.value);
                            }}
                          ></IonDatetime>
                        </div>
                        <div className="NewForm_input_container">
                          <b>End Date</b>
                          <IonDatetime
                            displayFormat="MMM DD, YYYY HH:mm"
                            style={{
                              border: "1px #ccc solid",
                              borderRadius: 5,
                              backgroundColor: "white",
                            }}
                            onIonChange={(e) => {
                              _EndDate(e.detail.value);
                            }}
                          ></IonDatetime>
                        </div>
                      </>
                    ) : null}
                    <div className="NewForm_input_container">
                      <b>Image</b>
                      <input
                        accept="image/*"
                        style={{
                          border: "1px #ccc solid",
                          padding: 5,
                          marginBottom: 10,
                          marginTop: 10,
                          width: "100%",
                          borderRadius: 5,
                          backgroundColor: "white",
                        }}
                        onChange={async (e: any) => {
                          _heroImage(e.target.files[0]);
                          // params.updateImage(e.target.files[0]);
                        }}
                        type="file"
                      ></input>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Title</b>
                      <IonInput
                        className="NewForm_input"
                        value={name}
                        placeholder="Enter Input"
                        onIonChange={(e) => _name(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Address Line 1</b>
                      <IonInput
                        className="NewForm_input"
                        value={address1}
                        placeholder="Enter Input"
                        onIonChange={(e) => _address1(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Address Line 2</b>
                      <IonInput
                        className="NewForm_input"
                        value={address2}
                        placeholder="Enter Input"
                        onIonChange={(e) => _address2(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>City</b>
                      <IonInput
                        className="NewForm_input"
                        value={cityField}
                        placeholder="Enter Input"
                        onIonChange={(e) => _cityField(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>State</b>
                      <IonInput
                        className="NewForm_input"
                        value={state}
                        placeholder="Enter Input"
                        onIonChange={(e) => _state(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Zip</b>
                      <IonInput
                        className="NewForm_input"
                        value={zip}
                        placeholder="Enter Input"
                        onIonChange={(e) => _zip(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Phone</b>
                      <IonInput
                        className="NewForm_input"
                        value={phone}
                        placeholder="Enter Input"
                        onIonChange={(e) => _phone(e.detail.value)}
                      ></IonInput>
                    </div>

                    <div className="NewForm_input_container">
                      <b>Website</b>
                      <IonInput
                        className="NewForm_input"
                        value={website}
                        placeholder="Enter Input"
                        onIonChange={(e) => _website(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Description</b>
                      <IonTextarea
                        onIonChange={(e) => {
                          _description(e.detail.value!);
                        }}
                        style={{
                          backgroundColor: "#fff",
                          border: "1px #ccc solid",
                          paddingLeft: 5,
                          paddingRight: 5,
                          marginTop: 10,
                          marginBottom: 10,
                          borderRadius: 8,
                        }}
                        rows={16}
                        value={description}
                      ></IonTextarea>
                    </div>
  
                    <div style={{paddingTop:50}}>
                      <IonButton
                        color="secondary"
                        shape="round"
                        onClick={() => {
                          _submitted(true);
                          if (type == "events") {
                            let newEvent = {
                              name,
                              address1,
                              address2,
                              city: cityField,
                              state,
                              zip,
                              contactPhone:phone,
                              contactName:user.name,
                              contactEmail:user.email,
                              website,
                              shortDescription: description,
                              description: description,
                              dates: [
                                {
                                  EndDate,
                                  StartDate,
                                },
                              ],
                            };
                            Db.DraftEvent(newEvent).then(async (data) => {
                              console.log("Event Created", data);
                              if (heroImage) {
                                await Db.Upload(heroImage, "events", "heroImage", data.id).then(
                                  (data) => {
                                   console.log("Upload Success")
                                  }
                                );
                              }

                            });
                          }
                          if (type == "places") {
                            let newPlace = {
                              name,
                              address1,
                              address2,
                              city: cityField,
                              state,
                              zip,
                              contactPhone:phone,
                              contactName:user.name,
                              contactEmail:user.email,
                              website,
                              shortDescription: description,
                              longDescription: description,
                            };
                            Db.DraftPlace(newPlace).then(async (data) => {
                              console.log("Place Created", data);
                              if (heroImage) {
                                await Db.Upload(heroImage, "places", "heroImage", data.id).then(
                                  (data) => {
                                   console.log("Upload Success")
                                  }
                                );
                              }

                            });
                          }
                        }}
                        expand="block"
                      >
                        Submit
                      </IonButton>
                    </div>
                    {urlParams && urlParams.web == true ? null : (
                      <div style={{ paddingTop: 40, paddingBottom: 200 }}>
                        <IonButton
                          shape="round"
                          onClick={() => {
                            history.goBack();
                          }}
                          color="warning"
                          size="small"
                          expand="block"
                        >
                          Cancel
                        </IonButton>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>Thank You </h4>
              <div
                style={{ padding: 20, paddingBottom: 10, textAlign: "center" }}
              >
                {name} has been submitted and is now pending approval
              </div>
              <div style={{ padding: 20, paddingBottom: 200 }}>
                <IonButton
                  shape="round"
                  onClick={() => {
                    history.goBack();
                  }}
                  color="success"
                  size="small"
                  expand="block"
                >
                  Finish
                </IonButton>
              </div>
            </>
          )}
        </IonContent>
      ) : (
        <h2 style={{ paddingTop: "40vh", textAlign: "center" }}>Loading...</h2>
      )}
    </IonPage>
  );
}

export default FormNew;
