import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { stat } from "fs";
import {
  arrowBackCircle,
  arrowBackCircleOutline,
  ellipseOutline,
} from "ionicons/icons";
import _, { update } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ProfileAction from "../components/Profile/ProfileAction";
import ProfileDetails from "../components/Profile/ProfileDetails";
import ProfileEdit from "../components/Profile/ProfileEdit";
import ProfileHeroImage from "../components/Profile/ProfileHeroImage";
import ProfileIconsList from "../components/Profile/ProfileIconsList";
import ProfileRelated from "../components/Profile/ProfileRelated";
import { ICityHome, IHeroImage } from "../Interfaces";
import Db from "../services/db";
import "./Profile.css";

function Profile() {
  const state: any = useLocation().state;
  let history = useHistory();
  const [icons, _icons] = useState<any>();
  const [item, _item] = useState<any>();
  const [image, _image] = useState<any>();
  const [related, _related] = useState<any>();
  const [loading, _loading] = useState(false);
  const [profileDetailed, _profileDetailed] = useState<any>();
  const [homeLayout, setHomeLayout] = useState<ICityHome>();
  const [editing, _editing] = useState(false);
  const [updating, _updating] = useState(false);

  useEffect(() => {
    _loading(true);
    _related(state.related);
    _item(state.item);
    setTimeout(() => {
      update(state.item);
      Db.ga.page("Profile", {
        item: state.item.id,
        itemName: state.item.name,
        type: state.item.type,
      });
    }, 200);
  }, []);

  async function update(newItem: any) {
    console.log("newItem", newItem);
    if (newItem) {
      await Db.ItemDetails(newItem).then((data) => {
        if(newItem.type){
                 data.type = newItem.type;
        }
 
        // console.log("item", data);
        // _item(data);
        _profileDetailed(data);
      });
    }
    let iconList = [];
    if (newItem?.phone) {
      iconList.push({
        icon: "phone",
        Title: "Phone",
        link: "tel:" + newItem.phone,
      });
    }
    if (newItem?.website) {
      let newWebsite = newItem.website;
      if (!_.toLower(newWebsite).includes("http")) {
        newWebsite = "https://" + newWebsite;
      }
      iconList.push({ icon: "explore", Title: "Website", link: newWebsite });
    }

    _icons(iconList);
    _loading(false);
    _updating(false);
  }
  if (item && related) {
    return (
      <IonPage>
        {profileDetailed ? (
          <ProfileEdit
            item={profileDetailed}
            editing={editing}
            clicked={async () => {
              _editing(!editing);
              if (editing) {
                _updating(true);
                //alert(editing);

                Db.Editor(item).then(() => {
                  console.log("Updated");

                  Db.HomeLayout().then((data) => {
                    localStorage.setItem("data:home", JSON.stringify(data));
                  });
                  Db.Me().then((data) => {
                    localStorage.setItem("user", JSON.stringify(data));
                    if (data.vendor != undefined) {
                      Db.MyVendor().then((data2) => {
                        console.log("myvendor", data2);
                        localStorage.setItem("vendor", JSON.stringify(data2));
                      });
                    }
                  });
                });
                if (image) {
                  await Db.Upload(image, item.type, "heroImage", item.id).then(
                    (data) => {
                      _updating(true);
                      Db.ItemDetails(item).then((data) => {
                        _item(data);
                        _updating(false);
                      });
                    }
                  );
                }
              }
            }}
          />
        ) : null}
        <IonHeader
          style={{ backgroundColor: "transparent", marginBottom: -100 }}
        >
          <IonToolbar style={{ backgroundColor: "transparent" }}>
            <IonIcon
              onClick={() => {
                history.goBack();
              }}
              icon={arrowBackCircle}
              size="large"
            ></IonIcon>
            <IonIcon
              onClick={() => {
                history.goBack();
              }}
              style={{ color: "white", marginLeft: -32 }}
              icon={arrowBackCircleOutline}
              size="large"
            ></IonIcon>
          </IonToolbar>
        </IonHeader>

        <IonContent style={{ backgroundColor: "#f7f7f7" }} fullscreen>
          {loading != true && item ? (
            <>
              <ProfileHeroImage item={item}></ProfileHeroImage>
              {profileDetailed ? (
                <ProfileIconsList linkedClicked={(linked)=>{
        
                  linked.place.type = "places"
                  _loading(true);
                  _item(linked.place);
                  setTimeout(() => {
                    update(linked.place);
                  }, 200);
                }} item={profileDetailed} icons={icons} />
              ) : (
                <>
                  {item ? <ProfileIconsList linkedClicked={(linked)=>{
           
                  linked.place.type = "places"
                  _loading(true);
                  _item(linked.place);
                  setTimeout(() => {
                    update(linked.place);
                  }, 200);
                }} item={item} icons={icons} /> : null}
                </>
              )}

              <ProfileDetails
                updateImage={(fromimage) => {
                  _image(fromimage);
                }}
                updateItem={(fromitem) => {
                  _item(fromitem);
                }}
                editing={editing}
                item={item}
              ></ProfileDetails>
              {_.size(related) >= 1 ? (
                <>
                  {item.HideRelated == true ? null : (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          paddingTop: 10,
                          fontWeight: 500,
                        }}
                      >
                        Related
                      </div>
                      <ProfileRelated
                        clicked={(data) => {
                          _loading(true);
                          _item(data);
                          setTimeout(() => {
                            update(data);
                          }, 200);
                        }}
                        list={related}
                        item={item}
                      />
                    </>
                  )}
                  <IonToast
                    isOpen={updating}
                    onDidDismiss={() => _updating(false)}
                    message="Updating..."
                    duration={5000}
                    position="top"
                  />
                </>
              ) : null}
            </>
          ) : (
            <h2 style={{ paddingTop: "40vh", textAlign: "center" }}>
              Loading...
            </h2>
          )}

          <div style={{ backgroundColor: "#f7f7f7", height: 100 }}></div>
        </IonContent>
      </IonPage>
    );
  } else {
    return null;
  }
}

export default Profile;
