import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useEffect, useState } from "react";
import ContactInfo from "../components/Home/ContactInfo";
import HeroImage from "../components/Home/HeroImage";
import HomeCategories from "../components/Home/HomeCategories";
import CircleCategories from "../components/Home/CircleCategories";
import IconsList from "../components/Home/IconsList";
import SocialIcons from "../components/Home/SocialIcons";
import { ICityHome } from "../Interfaces";
import Db from "../services/db";
import "./Home.css";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import HomeText from "../components/Home/HomeText";
import HomeSingle from "../components/Home/HomeSingle";
import _ from "lodash";
import { useHistory } from "react-router";
import OneSignal from 'onesignal-cordova-plugin';

function Home() {
  const [homeLayout, setHomeLayout] = useState<ICityHome>();
  const [appVersion, _appVersion] = useState();
  const [vendor, _vendor] = useState<any>();

  const history = useHistory();

  useIonViewWillLeave(() => {
    refresh();
  });

  useIonViewDidEnter(() => {
    if (localStorage.getItem("vendor")) {
      let localVendor: any = localStorage.getItem("vendor");
      try {
        _vendor(JSON.parse(localVendor));
      } catch {
        localStorage.removeItem("vendor");
      }
      Db.ga.page("Home");
      //console.log("vendor", localVendor);
    }
  });

  useEffect(() => {
    Db.InitOnsignal();


    Db.ga.page("Home");
    Db.ga.track("AppLoaded");
    refresh();
  }, []);



  function refresh() {
    getHome();
    Db.Config().then((data: any) => {
      if (data && data.id) {
        localStorage.setItem("data:config", JSON.stringify(data));
      }
    });
    if (localStorage.getItem("vendor")) {
      let localVendor: any = localStorage.getItem("vendor");
      try {
        _vendor(JSON.parse(localVendor));
      } catch {
        localStorage.removeItem("vendor");
      }

      //console.log("vendor", localVendor);
    }
    if (Capacitor.isNativePlatform()) {
      App.getInfo().then((data: any) => {
        _appVersion(data.version);
      });
    }
  }

  function getHome() {
    try {
      let localStoreHome: any = localStorage.getItem("data:home");
      let localHome = JSON.parse(localStoreHome);

      if (localHome.id) {
        setHomeLayout(localHome);
        Db.HomeLayout().then((data) => {
          localStorage.setItem("data:home", JSON.stringify(data));
          localStorage.setItem("data:city", JSON.stringify(data.city));
          
        });
      } else {
        Db.HomeLayout().then((data) => {
          localStorage.setItem("data:home", JSON.stringify(data));
          localStorage.setItem("data:city", JSON.stringify(data.city));
          setHomeLayout(data);
        });
      }
    } catch {
      localStorage.removeItem("data:home");
      Db.HomeLayout().then((data) => {
        localStorage.setItem("data:home", JSON.stringify(data));
        if(data && data.city){
        localStorage.setItem("data:city", JSON.stringify(data.city));
        }
        setHomeLayout(data);
      });
    }
  }

  function List() {
    return homeLayout?.layout.map((item: any, index: number) => {
      if (item.__component == "home-layout-heroimage.hero-image") {
        return <HeroImage key={index} layout={item} />;
      } else if (item.__component == "home-layout-icons.icons") {
        return <IconsList key={index} data={item} />;
      } else if (item.__component == "layout-listicons.home-categories") {
        return <HomeCategories key={index} data={item} />;
      } else if (
        item.__component == "layout-listicons.home-catergories-circle"
      ) {
        return <CircleCategories key={index} data={item} />;
      } else if (item.__component == "layout-contactinfo.contact-info") {
        return <ContactInfo key={index} data={item} />;
      } else if (item.__component == "home-layout-social.social") {
        return <SocialIcons key={index} data={item} />;
      } else if (item.__component == "home-layout-text.text") {
        return <HomeText key={index} data={item} />;
      } else if (item.__component == "home-layout-item.single-post-card") {
        return <HomeSingle key={index} type={"single-post-card"} data={item} />;
      } else if (item.__component == "home-layout-item.single-place-item") {
        return (
          <HomeSingle key={index} type={"single-place-item"} data={item} />
        );
      } else if (item.__component == "home-layout-item.single-event-item") {
        return (
          <HomeSingle key={index} type={"single-event-item"} data={item} />
        );
      } else if (item.__component == "home-layout-item.list") {
        return <HomeSingle key={index} type={"list"} data={item} />;
      } else {
        return null;
      }
    });
  }

  return (
    <IonPage>
      <IonHeader translucent={true}></IonHeader>
      <IonContent style={{ backgroundColor: "#f7f7f7" }} fullscreen>
        {homeLayout ? (
          <List />
        ) : (
          <>
            <div
              style={{
                textAlign: "center",
                paddingTop: 100,
                fontSize: 20,
                paddingBottom: 100,
              }}
            >
              Loading...
            </div>
            <div
              onClick={() => {
                refresh();
              }}
              style={{
                textAlign: "center",
                marginTop: 40,
                marginBottom: 40,
                color: "blue",
                textDecoration: "underline",
              }}
            >
              retry?
            </div>
          </>
        )}
        <div
          style={{
            backgroundColor: "#f7f7f7",
            height: "100px",
            textAlign: "center",
            paddingTop: 50,
            fontSize: 10,
          }}
        >
          <a href="https://yo.city" target="_blank">
            <div>App By Yo.City</div> <div style={{marginBottom:100}}>{appVersion}</div>
          </a>
        </div>
        {vendor && vendor.id ? (
          <div
            style={{
              zIndex: 999999,
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              height: 80,
              padding: 7,
              borderTop: "1px #ccc solid",
              backgroundColor: "white",
              borderRadius: "8px 8px 0px 0px ",
            }}
          >
            <IonButton
              expand="block"
              color="secondary"
              shape="round"
              style={{
                width: "calc(100% - 3px)",
              }}
              routerLink="/vendorScan"
            >
              Scanner
            </IonButton>
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
}

export default Home;
