import React, { useEffect, useState } from "react";
import { IonSlides, IonSlide, IonContent, IonSkeletonText } from "@ionic/react";
import "./ImageSlide.css";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import axios from "axios";

interface IParams {
  src: string;
  imageStyle?: any;
}

const CACHE_FOLDER = "CACHED-IMG";

export default function CachedImage(params: IParams) {
  const [newSrc, _newSrc] = useState<any>();
  async function getSrc(src: string) {
    const imageName: any = src.split("/").pop();
    const fileType = imageName.split(".").pop();

    let cache = "";

    return await Filesystem.readFile({
      directory: Directory.Cache,
      path: CACHE_FOLDER + "/" + imageName,
    })
      .then((readFile) => {
        return `data:image/` + fileType + ";base64," + readFile.data;
      })
      .catch(async (e) => {
        //Write the file
        return await storeImage(src, CACHE_FOLDER + "/" + imageName).then(
          (data) => {
            return `data:image/` + fileType + ";base64," + data;
          }
        );
      });
  }

  async function storeImage(url: string, path: string) {
    return await getBase64(url).then(async (base64Data) => {
      //console.log("response", base64Data);
      await Filesystem.writeFile({
        path: path,
        data: base64Data,
        directory: Directory.Cache,
        recursive: true,
      });
      return base64Data;
    });
  }

  function getBase64(url: string) {
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      );
  }
  useEffect(() => {
    getSrc(params.src).then((data) => {
      _newSrc(data);
    });
  }, []);

  if (newSrc) {
    return <img style={params.imageStyle} src={newSrc} />;
  } else {
    return (
      <IonSkeletonText animated style={params.imageStyle}></IonSkeletonText>
    );
  }
}
