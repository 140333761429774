import { IHeroImage } from "../../Interfaces";
import Db from "../../services/db";
import "./HeroImage.css";
import {
  CreateAnimation,
  Animation,
  IonSpinner,
  IonSkeletonText,
} from "@ionic/react";
import { useState } from "react";

interface ContainerProps {}

interface iParams {
  layout: IHeroImage;
}

export default function HeroImage(params: iParams) {
  const [newSrc, _newSrc] = useState<any>();
  let heroImage = "";
  if (
    params.layout.Image &&
    params.layout.Image.formats &&
    params.layout.Image.formats.medium
  ) {
    heroImage = params.layout.Image.formats.medium.url;
    Db.getSrc(heroImage).then((data) => {
      _newSrc(data);
    });
  } else {
    heroImage = params.layout.Image.url;
    Db.getSrc(heroImage).then((data) => {
      _newSrc(data);
    });
  }
  if (newSrc) {
    return (
      <div className="HeroImage_heroImageContainer">
        <div
          className="HeroImage_heroImageContainerDiv"
          style={{
            backgroundImage: "url(" + newSrc + ")",
          }}
        ></div>
        <div className="HeroImage_title">
          <div className="HeroImage_titleText">{params.layout.Title}</div>
          <div className="HeroImage_titleSubText">{params.layout.SubTitle}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="HeroImage_heroImageContainer">
        <div className="HeroImage_heroImageContainerDiv"></div>
        <div className="HeroImage_title">
          <div className="HeroImage_titleText"></div>
          <div className="HeroImage_titleSubText"></div>
        </div>
      </div>
    );
  }
}
