import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonRow,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBack,
  arrowBackCircle,
  closeCircleOutline,
  ellipseOutline,
  list,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../components/BackButton";
import ImageSlide from "../components/ImageSlide";
import Card2 from "../components/Card2";
import Db from "../services/db";
import "./Maps.css";
import ImageModal from "../components/ImageModal";

function Maps() {
  const [maps, _maps] = useState();
  const state: any = useLocation().state;
  const [showModal, _showModal] = useState(false);
  const [modalItem, _modalItem] = useState<any>();

  useEffect(() => {
    Db.ga.page("Maps");
    console.log("MAPS LIST", state.list);
    Db.Maps().then((data: any) => {
      console.log("maps", data);
      _maps(data);
    });
  }, []);

  function List({ items }: any) {
    let x = 1;

    return items.map((item: any) => {
      x++;

      return (
        <>
          <Card2
            key={x}
            name={item.title}
            image={item.image}
            resize="cover"
            action={null}
            startDate=""
            endDate=""
            shortDescription={item.description}
            clicked={() => {
              //alert("clicked");
              _showModal(true);
              _modalItem(item);
            }}
          />
        </>
      );
    });
  }

  return (
    <IonPage>
      {maps && state && state.list ? (
        <>
          <IonHeader style={{ backgroundColor: "white" }}>
            <IonToolbar style={{ backgroundColor: "white" }}>
              <IonButtons slot="start">
                <BackButton />
              </IonButtons>
              <IonTitle>{state.list.Title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent style={{ marginTop: 200, backgroundColor: "#f7f7f7" }}>
            <IonList>
              <List items={maps} />
            </IonList>
          </IonContent>
          {modalItem ? (
            <ImageModal
              showModal={showModal}
              title={modalItem.title}
              image={modalItem.image}
              place={modalItem.place ? modalItem.place : null}
              link={modalItem.link ? modalItem.link : null}
              onShowModal={(data) => {
                _showModal(data);
              }}
            />
          ) : null}
        </>
      ) : null}
    </IonPage>
  );
}

export default Maps;
