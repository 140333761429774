import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItemSliding,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonFooter,
  IonInput,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import "./ListFooter.css";

interface iParams {
  list: any;
  onSearch: (text: any) => void;
}

export default function ListFooter(params: iParams) {
  const [text, setText] = useState<string>();
  return (
    <>
      <div className="ListFooter_container">
        <IonFooter className="ion-no-border">
          <IonGrid style={{ marginTop: -20 }}>
            <IonRow>
              <IonCol size="12">
                <div className="ListFooter_searchbox">
                  <IonInput
                    className="ListFooter_input"
                    value={text}
                    placeholder="Search"
                    onIonChange={(e) => {
                      setText(e.detail.value!);
                      params.onSearch(e.detail.value);
                    }}
                  ></IonInput>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                style={{ textAlign: "center", marginTop: -10, color: "#000" }}
              >
                SEARCH
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </div>
    </>
  );
}
