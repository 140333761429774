import React from "react";
import {
  IonSlides,
  IonSlide,
  IonContent,
  IonModal,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./ImageSlide.css";
import { closeCircleOutline, image, link } from "ionicons/icons";
import { IImage } from "../Interfaces";
import ImageSlide from "./ImageSlide";
import "./ImageModal.css";
import { useHistory } from "react-router";

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0.8,
  speed: 400,
  zoom: true,
};

interface IParams {
  image: IImage;
  title: string;
  showModal: boolean;
  link?: string;
  place?: any;
  onShowModal: (value: boolean) => void;
}

export default function ImageModal(params: IParams) {
  let history = useHistory();
  if (params.image) {
    return (
      <IonModal
        isOpen={params.showModal}
        cssClass="ImageModal_container"
        onDidDismiss={() => params.onShowModal(false)}
      >
        <IonHeader style={{ backgroundColor: "white" }}>
          <IonToolbar>
            <IonIcon
              onClick={() => {
                params.onShowModal(false);
              }}
              icon={closeCircleOutline}
              size="large"
            ></IonIcon>
            <IonTitle>{params.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent style={{ backgroundColor: "white" }}>
          <div className="ImageModal_image_container">
            <ImageSlide image={params.image} />
          </div>
        </IonContent>
        {params.link || params.place ? (
          <IonFooter style={{ backgroundColor: "white" }}>
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  {params.link ? (
                    <IonCol>
                      <IonButton
                        expand="block"
                        size="small"
                        color="secondary"
                        shape="round"
                        href={params.link}
                        target="_blank"
                      >
                        Link
                      </IonButton>
                    </IonCol>
                  ) : null}
                  {params.place ? (
                    <IonCol>
                      <IonButton
                        expand="block"
                        size="small"
                        color="secondary"
                        shape="round"
                        onClick={() => {
                          let place = params.place;
                          place.type = "places";
                          history.push("/profile", {
                            item: place,
                            related: [],
                          });
                        }}
                      >
                        {params.place.name}
                      </IonButton>
                    </IonCol>
                  ) : null}
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonFooter>
        ) : null}
      </IonModal>
    );
  } else {
    return null;
  }
}
