import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItemSliding,
  IonTextarea,
  IonInput,
  IonCard,
  IonCardContent,
  IonButton,
} from "@ionic/react";
import {
  ICategoryItem,
  IHeroImage,
  IIconListItem,
  IPage,
} from "../../Interfaces";
import Db from "../../services/db";
import "./ProfileDetails.css";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { useEffect, useState } from "react";
import { navigateCircleOutline, pin } from "ionicons/icons";
import { useParams } from "react-router";
import _ from "lodash";

import { Geolocation } from "@capacitor/geolocation";

interface iParams {
  item: ICategoryItem;
  editing: boolean;
  updateItem: (item: any) => void;
  updateImage: (image: any) => void;
}

export default function ProfileDetails(params: iParams) {
  const [addressLink, _addressLink] = useState<any>();
  const [description, _description] = useState(params.item.description);
  const [address1, _address1] = useState(params.item.address1);
  const [address2, _address2] = useState(params.item.address2);
  const [city, _city] = useState(params.item.city);
  const [state, _state] = useState(params.item.state);
  const [zip, _zip] = useState(params.item.zip);
  const [phone, _phone] = useState(params.item.phone);
  const [website, _website] = useState(params.item.website);
  const [name, _name] = useState(params.item.name);
  const [heroImage, _heroImage] = useState(params.item.name);

  let iconList = [];
  useEffect(() => {
    if (params.item.address1) {
      let link =
        "https://www.google.com/maps?saddr=My+Location&daddr=" +
        params.item.address1 +
        "+" +
        params.item.city +
        "+" +
        params.item.state +
        "+" +
        params.item.zip;

      _addressLink(link);
    }
  }, []);


  async function getLocation(){
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    return await Geolocation.getCurrentPosition(options).then(
      (position: any) => {
        return position;
      },
      (error) => {
        alert(
          "Unable to get location. Please check app locations permissions in settings"
        );
      }
    );
  }

  function DateRow() {
    return (
      <>
        {params.item.startDate ? (
          <IonRow>
            {params.item.startDate ? (
              <IonCol>
                <div className="ProfileDetails_date_container">
                  <div className="ProfileDetails_label">from</div>
                  <div className="ProfileDetails_date">
                    {moment(params.item.startDate).format("M/D/YY")}
                  </div>
                  {params.item.allDay != true ? (
                    <div className="ProfileDetails_time">
                      {moment(params.item.startDate).format("LT")}
                    </div>
                  ) : null}
                </div>
              </IonCol>
            ) : null}
            {params.item.endDate ? (
              <IonCol>
                <div className="ProfileDetails_date_container">
                  <div className="ProfileDetails_label">to</div>
                  <div className="ProfileDetails_date">
                    {moment(params.item.endDate).format("M/D/YY")}
                  </div>
                  {params.item.allDay != true ? (
                    <div className="ProfileDetails_time">
                      {moment(params.item.endDate).format("LT")}
                    </div>
                  ) : null}
                </div>
              </IonCol>
            ) : null}
          </IonRow>
        ) : null}
      </>
    );
  }

  function readFileDataAsBase64(e: any) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  return (
    <>
      <div className="ProfileDetails_container">
        <IonGrid>
          {DateRow()}
          <IonRow>
            <IonCol size={params.editing ? "12" : "8"}>
              <>
                {!params.editing ? null : (
                  <>
                    Image
                    <input
                      style={{
                        border: "1px #ccc solid",
                        padding: 5,
                        marginBottom: 10,
                        marginTop: 10,
                        width: "100%",
                        borderRadius: 5,
                        backgroundColor: "white",
                      }}
                      onChange={async (e: any) => {
                        _heroImage(e.target.files[0]);
                        params.updateImage(e.target.files[0]);
                      }}
                      type="file"
                    ></input>
                  </>
                )}

<>
                {!params.editing ? null : (
                  <>
                    
                    <IonButton
                      expand="block"
                      size="small"
                      style={{marginTop:30, marginBottom:40}}
                      onClick={async () => {
                        let newItem: any = params.item;
                        let position = await getLocation();
                        newItem.region = {
                          name:"From App",
                          lat: position.coords.latitude,
                          lon: position.coords.longitude
                        };
                        params.updateItem(newItem);
                      }}
                
                    >Set Location</IonButton>
                  </>
                )}
              </>


                {!params.editing ? null : (
                  <>
                    Title
                    <IonInput
                      className="Detail_Input"
                      value={name}
                      placeholder="Enter Input"
                      onIonChange={(e) => _name(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.name = name;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>
              <>
                {!params.editing ? null : (
                  <>
                    Website
                    <IonInput
                      className="Detail_Input"
                      value={website}
                      placeholder="Enter Input"
                      onIonChange={(e) => _website(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.website = website;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>
              <>
                {!params.editing ? null : (
                  <>
                    Phone
                    <IonInput
                      className="Detail_Input"
                      value={phone}
                      placeholder="Enter Input"
                      onIonChange={(e) => _phone(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.phone = phone;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>
              <>
                {!params.editing ? (
                  <div className="Profile_detail">{address1}</div>
                ) : (
                  <>
                    Address
                    <IonInput
                      className="Detail_Input"
                      value={address1}
                      placeholder="Enter Input"
                      onIonChange={(e) => _address1(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.address1 = address1;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>

              <>
                {!params.editing ? (
                  <div className="Profile_detail">{address2}</div>
                ) : (
                  <>
                    Address
                    <IonInput
                      className="Detail_Input"
                      value={address2}
                      placeholder="Enter Input"
                      onIonChange={(e) => _address2(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.address2 = address2;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>

              <>
                {!params.editing ? (
                  <div className="Profile_detail">
                    {city}, {state} {zip}
                  </div>
                ) : (
                  <>
                    City
                    <IonInput
                      className="Detail_Input"
                      value={city}
                      placeholder="Enter Input"
                      onIonChange={(e) => _city(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.city = city;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                    State
                    <IonInput
                      className="Detail_Input"
                      value={state}
                      placeholder="Enter Input"
                      onIonChange={(e) => _state(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.state = state;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                    Zip
                    <IonInput
                      className="Detail_Input"
                      value={zip}
                      placeholder="Enter Input"
                      onIonChange={(e) => _zip(e.detail.value!)}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.zip = zip;
                        params.updateItem(newItem);
                      }}
                    ></IonInput>
                  </>
                )}
              </>
            </IonCol>
            {params.editing ? null : (
              <IonCol size="4" className="ProfileDetails_icon">
                <IonRow>
                  <IonCol>
                    <a
                      style={{
                        textAlign: "center",
                        textDecoration: "none",
                        color: "unset",
                      }}
                      href={addressLink}
                      target="_blank"
                    >
                      <IonIcon icon={navigateCircleOutline}></IonIcon>
                    </a>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ProfileDetails_icon_title">
                    <a
                      style={{
                        textAlign: "center",
                        textDecoration: "none",
                        color: "unset",
                      }}
                      href={addressLink}
                      target="_blank"
                    >
                      Directions
                    </a>
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
          </IonRow>

          {params.item.audioClipMp3 ? (
            <IonRow>
              <IonCol>
                <audio
                  style={{ width: "100%" }}
                  controlsList="nodownload"
                  controls
                  src={params.item.audioClipMp3.url}
                ></audio>
              </IonCol>
            </IonRow>
          ) : null}
     
          <IonRow>
            <IonCol>
              {description ? (
                <div className="Profile_description">
                  {params.editing ? (
                    <IonTextarea
                      onIonChange={(e) => {
                        _description(e.detail.value!);
                      }}
                      onBlur={() => {
                        let newItem: any = params.item;
                        newItem.description = description;
                        params.updateItem(newItem);
                      }}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px #ccc solid",
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 8,
                      }}
                      rows={16}
                      value={description}
                    ></IonTextarea>
                  ) : (
                    <ReactMarkdown>{description}</ReactMarkdown>
                  )}
                </div>
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
