import {
  IonAvatar,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { stat } from "fs";
import {
  arrowBack,
  arrowBackCircle,
  arrowBackCircleOutline,
  ellipseOutline,
} from "ionicons/icons";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import ListFooter from "../components/List/ListFooter";

import ListHeader, { updateMapIndex } from "../components/List/ListHeader";
import ListItems from "../components/List/ListItems";
import ListTour from "../components/List/ListTour";
import Db from "../services/db";
import "./List.css";

function List() {
  const [list, _list] = useState<any>();
  const [loading, _loading] = useState(true);
  const state: any = useLocation().state;
  let history = useHistory();
  let urlParams: any = useParams();

  useIonViewDidEnter(() => {
    onLoad(urlParams.id);
  });

  function setupCategory(data: any) {
    let newListItems = [];
    for (let e of data.linkedEvents) {
      e.type = "events";
      newListItems.push(e);
    }
    for (let p of data.linkedPlaces) {
      p.type = "places";
      newListItems.push(p);
    }
    for (let po of data.linkedPosts) {
      po.type = "posts";
      newListItems.push(po);
    }
    for (let c of data.linkedCategories) {
      c.type = "categories";
      newListItems.push(c);
    }
    let newList = data;
    newList.items = newListItems;
    console.log("newList", newList);
    _list(newList);
    _loading(false);
  }

  function onLoad(id: number) {
    console.log("List ID", urlParams);
    _loading(true);
    let timeOut = setTimeout(() => {
      if (state && state.list) {
        console.log("state", state);
        _list(state.list);
        _loading(false);
      }
    }, 5000);

    //alert("params");
    if (state && state.list && state.list.name) {
      Db.ga.page("List", {
        list: urlParams.id,
        listName: state.list.name,
      });
    }

    Db.Category(id).then((data: any) => {
      if (data) {
        if (data.statusCode == 401) {
          localStorage.removeItem("user");
          Db.Category(id, false).then((data2: any) => {
            clearTimeout(timeOut);
            setupCategory(data2);
            _loading(false);
          });
        } else {
          clearTimeout(timeOut);
          setupCategory(data);
          _loading(false);
        }
      } else {
        Db.Category(id, false).then((data2: any) => {
          if (data2) {
            clearTimeout(timeOut);
            setupCategory(data2);
            _loading(false);
          }
        });
      }
    });
  }

  return (
    <IonPage>
      {list ? (
        <>
          <ListHeader list={list} />

          <ListItems
            refresh={(item) => {
              _loading(true);
              _list(item);
              setTimeout(() => {
                _loading(false);
                onLoad(item.id);
              }, 100);
            }}
            list={list}
            onIndexChange={(item) => {
              updateMapIndex(item);
            }}
          />
        </>
      ) : (
        <>
          <div
            style={{
              height: "100vh",
              backgroundColor: "#f7f7f7",
              textAlign: "center",
              paddingTop: "40vh",
            }}
          >
            Loading...
          </div>
        </>
      )}
    </IonPage>
  );
}

export default List;
