import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack, arrowBackCircle, ellipseOutline, homeOutline } from "ionicons/icons";
import { useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../components/BackButton";
import UserActivity from "../components/User/UserActivity";
import UserEditor from "../components/User/UserEditor";
import UserPoints from "../components/User/UserPoints";
import UserWallets from "../components/User/UserWallets";
import QRCode from "react-qr-code";
import Db from "../services/db";
import "./User.css";
import _ from "lodash";
import UserRedeem from "../components/User/UserRedeem";

function UserProfile() {
  const [user, _user] = useState<any>();
  const [email, _email] = useState<any>();
  const [name, _name] = useState<any>();
  const [password, _password] = useState<any>();
  const [repassword, _repassword] = useState<any>();
  const [showSignUp, _showSignUp] = useState(true);
  const [actions, _actions] = useState<any>();
  const [loading, _loading] = useState(false);
  const [wallets, _wallets] = useState<any>();
  const [city , _city] = useState<any>();

  const [appConfig, _appConfig] = useState<any>();
  const state: any = useLocation().state;
  let history = useHistory();


  useEffect(() => {
    Db.ga.page("User");
    _loading(false);

    setupUser();

    let localCity = localStorage.getItem("data:city")
    if(localCity){
      _city(JSON.parse(localCity))
    }
    Db.City().then((data)=>{
      _city(data)
    })


  }, []);

  function setupUser() {
    let localUser:any = localStorage.getItem("user");
   

    console.log("localUser",localUser)
    let localWallets = localStorage.getItem("data:wallets")
    if(localWallets){
      _wallets(JSON.parse(localWallets))
    }
    setTimeout(()=>{
 if (localUser) {
      console.log("localUser",localUser)
      
      let theUser = JSON.parse(localUser);
      _user(theUser); 

      if (theUser && theUser[0]) {
        Db.MyWallets().then((data) => {
          console.log("my wallets", data);
          if (data && data[0]) {
            _wallets(data);
            localStorage.setItem("data:wallets",JSON.stringify(data))
          }
        });
      }

      Db.Actions().then((data) => {
        _actions(data);
      });

      // console.log("localUser", JSON.parse(localUser));
      Db.Me().then((data) => {
        _user(data);
        localStorage.setItem("user", JSON.stringify(data));
        console.log("user", data);
        Db.MyWallets().then((data3) => {
          console.log("my wallets", data3);
          if (data3) {
            _wallets(data3);
          }
        });
        if (data && data.vendor != undefined) {
          Db.MyVendor().then((data2) => {
            console.log("myvendor", data2);
            localStorage.setItem("vendor", JSON.stringify(data2));
          });
        } else {
          localStorage.removeItem("vendor");
        }
      });
    }
    },200)
   
    let localAppConfig: any = localStorage.getItem("data:config");
    _appConfig(JSON.parse(localAppConfig));
  }

  return (
    <IonPage style={{ backgroundColor: "#f7f7f7" }}>
      <>
        {state && state.list ? (
          <IonHeader translucent={true}>
            <IonToolbar>
              <IonButtons slot="start">
                <BackButton />
              </IonButtons>
              <IonTitle>{state.list.Title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        ) : (
          <IonHeader translucent={true}>
            <IonToolbar>
              <IonButtons slot="start">
                 <IonIcon
                 slot="end"
                  onClick={() => {
                    history.replace("/home");
                    console.log("BACK");
                  }}
                  icon={arrowBackCircle}
                  size="large"
                ></IonIcon>
              </IonButtons>
              <IonTitle></IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        {user ? (
          <IonContent>
            <div>
              <IonList>
                <IonListHeader style={{ paddingTop: 0 }}>
                  {user.name ? <>Name: {user.name}</> : null}
                </IonListHeader>
                <IonItem lines="none">Email: {user.email}</IonItem>{" "}
              </IonList>
              <IonGrid>
                {city && city.Coin && city.Coin.Title ? (
                <UserRedeem user={user} update={()=>{
                    Db.MyWallets().then((data3) => {
                      console.log("my wallets", data3);
                      if (data3) {
                        _wallets(data3);
                      }
                    });
                }}/>
                ):null }
                {/* {user.points_earned ? (
                  <IonRow>
                    <IonCol>
                      <UserPoints
                        earned={user.points_earned}
                        redeemed={user.points_redeemed}
                      />
                    </IonCol>
                  </IonRow>
                ) : null} */}
                {city && city.Coin && city.Coin.Title && wallets && wallets.length >= 1 ? (
                  <IonRow>
                    <IonCol>
                      {appConfig && appConfig.rewards ? (
                        <h3 style={{ textAlign: "center" }}>
                          {appConfig.rewards.title}
                        </h3>
                      ) : null}
                      <UserWallets 
                      wallets={wallets} 
                      update={()=>{
                           Db.MyWallets().then((data3) => {
                            console.log("my wallets", data3);
                            if (data3) {
                              _wallets(data3);
                            }
                          });
                      }}/>
                    </IonCol>
                  </IonRow>
                ) : null}
              </IonGrid>
              <IonItemDivider>Your Activity</IonItemDivider>
              <UserActivity actions={actions} user={user} />
              {user.editor && user.editor[0] ? (
                <UserEditor editors={user.editor} />
              ) : null}

              <div style={{ textAlign: "center" }}>
                <IonButton
                  color="secondary"
                  shape="round"
                  onClick={() => {
                    Db.Track("action","UserLogout");
                    localStorage.clear();
                    _user(undefined);

                  }}
                  style={{ width: 200, marginTop: 50 }}
                  size="small"
                >
                  Log Out
                </IonButton>
              </div>
            </div>
          </IonContent>
        ) : (
          <>
            {showSignUp == false ? (
              <IonContent style={{ width: "100%" }}>
                <div>
                  <IonList>
                    <IonListHeader
                      style={{ textAlign: "center", marginTop: 50 }}
                    >
                      <IonLabel>Login</IonLabel>
                    </IonListHeader>

                    <IonItem className="UserProfile_input" lines="none">
                      <IonInput
                        placeholder="Email"
                        onIonChange={(e) => {
                          _email(e.detail.value!);
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonItem className="UserProfile_input" lines="none">
                      <IonInput
                        placeholder="Password"
                        type="password"
                        onIonChange={(e) => {
                          _password(e.detail.value!);
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonItem
                      style={{ width: "100%", fontSize: 14, float: "right" }}
                      lines="none"
                      onClick={() => {
                        if (email) {
                          Db.UserForgot(email).then((data) => {
                            alert(
                              "Reset email from no-reply@yo.city has been sent"
                            );
                          });
                        } else {
                          alert("Please enter email");
                        }
                      }}
                    >
                      Forgot Password
                    </IonItem>
                  </IonList>
                </div>
                <div style={{ textAlign: "center" }}>
                  {password && loading != true ? (
                    <div>
                      <IonButton
                        color="secondary"
                        shape="round"
                        onClick={() => {
                          _loading(true);
                          Db.Auth(email, password).then(
                            (data) => {
                              setTimeout(() => {
                                _loading(false);
                              }, 1000);
                              // console.log("AUTH", data);
                              if (data && data.user) {
                                localStorage.setItem(
                                  "user",
                                  JSON.stringify(data.user)
                                );
                               
                                localStorage.setItem("jwt", data.jwt);
                                _user(data.user);
                              }
                              _loading(false);
                              setTimeout(() => {
                                setupUser();
                                if (data && data.vendor != undefined) {
                                  Db.MyVendor().then((data2) => {
                                    console.log("login myvendor", data2);
                                    localStorage.setItem(
                                      "vendor",
                                      JSON.stringify(data2)
                                    );
                                  });
                                } else {
                                  localStorage.removeItem("vendor");
                                }
                                Db.Track("action","UserLogin")
                                history.goBack()
                              }, 500);
                            },
                            (error) => {
                              alert("Invalid password or username");
                              _loading(false);
                            }
                          );
                        }}
                        style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                      >
                        Login
                      </IonButton>
                    </div>
                  ) : (
                    <div>
                      <IonButton
                        color="secondary"
                        shape="round"
                        disabled={true}
                        style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                      >
                        Login
                      </IonButton>
                    </div>
                  )}
                  <div>or</div>
                  <IonButton
                    onClick={() => {
                      _showSignUp(true);
                    }}
                    style={{ marginTop: 20, width: 200 }}
                    color="secondary"
                    size="small"
                    shape="round"
                  >
                    Sign Up!
                  </IonButton>
                </div>
              </IonContent>
            ) : (
              <IonContent style={{ width: "100%" }}>
                <div>
                  <IonList>
                    <IonListHeader
                      style={{ textAlign: "center", marginTop: 50 }}
                    >
                      <IonLabel>Sign Up</IonLabel>
                    </IonListHeader>
                    <IonItem className="UserProfile_input" lines="none">
                      <IonInput
                        onIonChange={(e) => {
                          _name(e.detail.value!);
                        }}
                        placeholder="Name"
                      ></IonInput>
                    </IonItem>

                    <IonItem className="UserProfile_input" lines="none">
                      <IonInput
                        onIonChange={(e) => {
                          _email(e.detail.value!);
                        }}
                        placeholder="Email"
                      ></IonInput>
                    </IonItem>
                    <IonItem className="UserProfile_input" lines="none">
                      <IonInput
                        onIonChange={(e) => {
                          _password(e.detail.value!);
                        }}
                        placeholder="Password"
                        type="password"
                      ></IonInput>
                    </IonItem>
                  </IonList>
                </div>
                <div style={{ textAlign: "center" }}>
                  {password && email ? (
                    <IonButton
                      onClick={() => {
                        Db.Register(email, password, name).then((data) => {
                          if (data && data.user) {
                            localStorage.setItem(
                              "user",
                              JSON.stringify(data.user)
                            );
                            localStorage.setItem("jwt", data.jwt);
                            _user(data.user);
                            Db.Track("action","UserSignup")
                            history.goBack()
                          }
                        });
                      }}
                      style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                      color="secondary"
                      shape="round"
                    >
                      Sign Up
                    </IonButton>
                  ) : (
                    <IonButton
                      disabled={true}
                      style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                      color="secondary"
                      shape="round"
                    >
                      Sign Up
                    </IonButton>
                  )}
                  <div>or</div>
                  <IonButton
                    onClick={() => {
                      _showSignUp(false);
                      _password(undefined);
                      _repassword(undefined);
                    }}
                    style={{ marginTop: 20, width: 200 }}
                    color="secondary"
                    shape="round"
                    size="small"
                  >
                    Login
                  </IonButton>
                </div>
              </IonContent>
            )}
          </>
        )}
      </>
    </IonPage>
  );
}

export default UserProfile;
