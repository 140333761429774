import { IonCol, IonGrid, IonRow } from "@ionic/react";
import _ from "lodash";
import { useHistory } from "react-router";

import Db from "../../services/db";
import Card2 from "../Card2";
import "./HomeSingle.css";

interface iParams {
  data: any;
  type: string;
}

export default function HomeSingle(params: iParams) {
  let history = useHistory();
  return (
    <IonGrid className="HomeSingle_container">
      {params.data && params.type == "list" ? (
        <>
          <Card2
            name={params.data.category.name}
            image={params.data.category.image}
            resize="cover"
            startDate=""
            action={null}
            endDate=""
            shortDescription={params.data.category.description}
            clicked={() => {
              //alert("clicked");
              history.push("/list/" + params.data.category.id, {
                list: params.data.category,
                related: [],
              });
            }}
          />
        </>
      ) : null}
      {params.data && params.type == "single-event-item" ? (
        <>
          <Card2
            name={params.data.event.name}
            image={params.data.event.heroImage}
            resize="cover"
            action={null}
            startDate=""
            endDate=""
            shortDescription={params.data.event.shortDescription}
            clicked={() => {
              //alert("clicked");
              history.push("/profile", {
                item: params.data.event,
                related: [],
              });
            }}
          />
        </>
      ) : null}
      {params.data && params.type == "single-place-item" ? (
        <>
          <Card2
            name={params.data.place.name}
            image={params.data.place.heroImage}
            resize="cover"
            action={null}
            startDate=""
            endDate=""
            shortDescription={params.data.place.shortDescription}
            clicked={() => {
              //alert("clicked");
              history.push("/profile", {
                item: params.data.place,
                related: [],
              });
            }}
          />
        </>
      ) : null}
      {params.data && params.type == "single-post-card" ? (
        <>
          <Card2
            name={params.data.post.title}
            image={params.data.post.heroImage}
            resize="cover"
            startDate=""
            action={null}
            endDate=""
            shortDescription={params.data.post.shortDescription}
            clicked={() => {
              //alert("clicked");
              history.push("/profile", { item: params.data.post, related: [] });
            }}
          />
        </>
      ) : null}
    </IonGrid>
  );
}
