import {
  IonList,
  IonContent,
  IonItem,
  IonHeader,
  IonItemDivider,
} from "@ionic/react";
import { list, search } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import Card2 from "../Card2";
import * as _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";

interface iParams {
  editors: any;
}

export default function UserEditor(params: iParams) {
  let history = useHistory();

  function List() {
    return params.editors
      .filter((x: any) => {
        return x.canEdit == true;
      })
      .map((item: any) => {
        return (
          <>
            {item.places ? <ListPlaces item={item} /> : null}
            {item.events ? <ListEvents item={item} /> : null}
            {item.posts ? <ListPost item={item} /> : null}
          </>
        );
      });
  }

  function ListPlaces(props: any) {
    //console.log("item", props.item);
    return props.item.places
      .filter((i: any) => {
        return i.name;
      })
      .map((place: any) => {
        let newItem = place;
        newItem.type = "places";
        if (newItem.published_at == null) {
          return (
            <IonItem style={{ fontWeigh: 400, color: "grey" }}>
              {newItem.name} - &nbsp;<i>pending</i>
            </IonItem>
          );
        } else {
          return (
            <IonItem
              onClick={() => {
                history.replace("/profile", { item: newItem, related: {} });
              }}
            >
              {newItem.name}
            </IonItem>
          );
        }
      });
  }
  function ListEvents(props: any) {
    return props.item.events
      .filter((i: any) => {
        return i.name;
      })
      .map((event: any) => {
        let newItem = event;
        newItem.type = "events";
        if (newItem.published_at == null) {
          return (
            <IonItem style={{ fontWeigh: 400, color: "grey" }}>
              {newItem.name} - &nbsp;<i>pending</i>
            </IonItem>
          );
        } else {
          return (
            <IonItem
              onClick={() => {
                history.replace("/profile", { item: newItem, related: {} });
              }}
            >
              {newItem.name}
            </IonItem>
          );
        }
      });
  }
  function ListPost(props: any) {
    return props.item.posts
      .filter((i: any) => {
        return i.name;
      })
      .map((post: any) => {
        let newItem = post;
        newItem.type = "posts";
        if (newItem.published_at == null) {
          return (
            <IonItem style={{ fontWeigh: 400, color: "grey" }}>
              {newItem.title} - &nbsp;<i>pending</i>
            </IonItem>
          );
        } else {
          return (
            <IonItem
              onClick={() => {
                history.replace("/profile", { item: newItem, related: {} });
              }}
            >
              {newItem.title}
            </IonItem>
          );
        }
      });
  }

  return (
    <IonList>
      <IonItemDivider>Things You Can Edit</IonItemDivider>
      {params.editors ? <List /> : null}
    </IonList>
  );
}
