import { IonCol, IonGrid, IonRow } from "@ionic/react";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import {
  IContactInfoBlock,
  IHeroImage,
  IHomeContactInfo,
} from "../../Interfaces";
import Db from "../../services/db";
import "./HomeText.css";

interface iParams {
  data: any;
}

export default function HomeText(params: iParams) {
  return (
    <IonGrid className="HomeText_container">
      <IonRow>
        <IonCol>
          {params.data && params.data.body ? (
            <>
              {params.data && params.data.centered == true ? (
                <div style={{ textAlign: "center" }}>
                  <ReactMarkdown>{params.data.body}</ReactMarkdown>
                </div>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <ReactMarkdown>{params.data.body}</ReactMarkdown>
                </div>
              )}
            </>
          ) : null}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
