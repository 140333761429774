import React from "react";
import { IonSlides, IonSlide, IonContent } from "@ionic/react";
import "./ImageSlide.css";
import { image } from "ionicons/icons";
import { IImage } from "../Interfaces";
import CachedImage from "./CachedImage";
import { Filesystem } from "@capacitor/filesystem";

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0.8,
  speed: 400,
  zoom: true,
};

interface IParams {
  image: IImage;
}

export default function ImageSlide(params: IParams) {
  return (
    <IonSlides className="ImageSlide_slides" pager={true} options={slideOpts}>
      <IonSlide>
        <div className="swiper-zoom-container">
          <CachedImage src={params.image.url} />
        </div>
      </IonSlide>
    </IonSlides>
  );
}
