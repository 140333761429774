import React from "react";


const Config = {
  host: "https://northwest-ne.yo.city",
  Segment: "northwest",
  mapboxToken:"pk.eyJ1IjoiYW50aXN0dWQiLCJhIjoiY2txcXE5M2QyMG8zZjMwcGl5ZHE2MWcxaiJ9.vyXZhZKmCUKSVaQxk9e7Ww",
  mapboxZoom:11,
  center:{lat:42.830117,lon:-103.009924},
  oneSignalAppId:"4a0bb179-c46b-4050-be0a-617f0a7e23ad"
};

export default Config;
