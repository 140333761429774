import { IonList, IonContent, IonItem } from "@ionic/react";
import { list, search } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ICategory, ICategoryItem } from "../../Interfaces";
import Card2 from "../Card2";

import "./UserActivity.css";
import * as _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";

interface iParams {
  user: any;
  actions: any;
}

export default function UserActivity(params: iParams) {
  let history = useHistory();

  function actionLookUp(id: any) {
    let found;
    if (params.actions) {
      for (let a of params.actions) {
        if (a.id == id) {
          found = a;
        }
      }
    }

    return found;
  }

  function List() {
    let index = 0;
    return params.user.actions.map((action: any) => {
      index++;
      let thing;
      let type;
      if (actionLookUp(action.action_id)) {
        action.details = actionLookUp(action.action_id);
        if (action.details.place) {
          thing = action.details.place.name;
          type = "places";
        }
        if (action.details.event) {
          thing = action.details.event.name;
          type = "events";
        }
        if (action.details.post) {
          thing = action.details.post.name;
          type = "posts";
        }
      }

      //console.log(action);
      if (thing) {
        return (
          <IonItem key={index}>
            {thing} - {action?.details?.button_complete_text}
          </IonItem>
        );
      } else {
        return null;
      }
    });
  }

  return <>{params.user ? <List /> : null}</>;
}
