import { useEffect } from "react";
import { ICategoryItem } from "../Interfaces";
import "./Card1.css";
import moment from "moment";
import { RouteComponentProps, useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import { arrowBackCircle } from "ionicons/icons";

interface ContainerProps {}

interface iParams {}

export default function BackButton(params: iParams) {
  let history = useHistory();
  return (
    <>
      <IonIcon
        onClick={() => {
          history.goBack();
          console.log("BACK");
        }}
        icon={arrowBackCircle}
        size="large"
      ></IonIcon>
    </>
  );
}
